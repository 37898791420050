export const buttonSizes = ['small', 'large'];
export const buttonTypes = ['button', 'submit'];
export const buttonActions = ['button', 'submit'];
export const buttonTargets = ['internal', 'external'];

export type ButtonSize = typeof buttonSizes[number];
export type ButtonType = typeof buttonTypes[number];
export type ButtonAction = typeof buttonActions[number];
export type ButtonTarget = typeof buttonTargets[number];

export interface ButtonLinkData {
  url: string;
  target?: ButtonTarget;
}
