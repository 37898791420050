import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

import { onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

// components
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer } from '@/components/partials';
import { ProductCard } from '@/components/features';

//constants
import { StockStatus } from '@/constants/statuses/returnsStatuses';

// types
import type { PropType } from 'vue';
import type { ProductItem } from '@/types/product';


export default /*@__PURE__*/_defineComponent({
  __name: 'ToyTemplate',
  props: {
  product: {
    required: true,
    type: Object as PropType<ProductItem>,
  },
},
  emits: ["add-status"],
  setup(__props, { emit: __emit }) {

const store = useStore();



const emit = __emit;

const addStatus: (status: StockStatus) => void = (status: StockStatus) => {
  emit('add-status', status);
};

onBeforeUnmount(() => store.dispatch('page/clearMessages'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      (__props.product)
        ? (_openBlock(), _createBlock(_unref(ProductCard), {
            key: 0,
            product: __props.product
          }, null, 8, ["product"]))
        : _createCommentVNode("", true),
      _createVNode(_unref(BaseSpacer), { size: "0-5" }),
      _createVNode(_unref(BaseGrid), { class: "product-return__actions" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), {
            span: { default: '6' },
            class: "bg-grey"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "success",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (addStatus(_unref(StockStatus).RETURNED_BEING_CLEANED)))
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Complete & Good condition ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), {
            span: { default: '6' },
            class: "bg-grey"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "warning",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (addStatus(_unref(StockStatus).IN_WORKSHOP_MISSING_PARTS)))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Missing parts ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), {
            span: { default: '6' },
            class: "bg-grey"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "danger",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (addStatus(_unref(StockStatus).IN_WORKSHOP_DAMAGED)))
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Poor condition ")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), {
            span: { default: '6' },
            class: "bg-grey"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                color: "warning",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (addStatus(_unref(StockStatus).IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS)))
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Poor condition & missing parts ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "0-5" }),
      _createVNode(_unref(BaseButton), {
        color: "default-dark",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (addStatus(_unref(StockStatus).WITH_CUSTOMER)))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" Not returned ")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})