import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form__error" }

import { BaseText, BaseSpacer } from '@/components/partials';

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseError',
  setup(__props) {

// components

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BaseSpacer), { size: "0-5" }),
    _createVNode(_unref(BaseText), { color: "danger" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ]))
}
}

})