import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "product-card",
  gap: "0"
}
const _hoisted_2 = { class: "product-card__see-more" }
const _hoisted_3 = { class: "product-card-icon-wrapper" }
const _hoisted_4 = { class: "product-card__image-container" }
const _hoisted_5 = { class: "product-card__image" }



// components
import { BaseSpacer, BaseImage, BaseText } from '@/components/partials';
import ButtonLink from '@/components/partials/BaseButton/ButtonLink.vue';

// types
import type { PropType } from 'vue';
import type { ProductItem } from '@/types/product';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductCard',
  props: {
  product: {
    required: true,
    type: Object as PropType<ProductItem>,
  },
},
  setup(__props) {

const props = __props;
console.log(props);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (__props.product)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(BaseSpacer), { size: "1" }),
          _createVNode(_unref(BaseText), {
            ref: "productTitle",
            class: "product-card__name",
            bold: true
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.product.name) + " ", 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(ButtonLink, {
                  ref: "productLink",
                  url: `https://whirli.com/toy/${__props.product?.slug}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "link",
                      class: "product-card__icon",
                      color: "#421780"
                    })
                  ]),
                  _: 1
                }, 8, ["url"]),
                _createTextVNode(" " + _toDisplayString(__props.product.name) + " ", 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.product.instuctionsPDFs, (pdf, i) => {
                  return (_openBlock(), _createBlock(ButtonLink, {
                    key: i,
                    url: `https://staging-whirli-prod-images.s3.eu-west-2.amazonaws.com/${pdf.replace(
              '/storage/',
              ''
            )}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "file-pdf",
                        class: "product-card__icon",
                        color: "#421780"
                      })
                    ]),
                    _: 2
                  }, 1032, ["url"]))
                }), 128))
              ])
            ]),
            _: 1
          }, 512)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_unref(BaseImage), {
              image: {
            url: `${__props.product?.image}`,
            height: '500',
            width: '500',
            title: `${__props.product?.name} image`,
          }
            }, null, 8, ["image"])
          ])
        ]),
        _createVNode(_unref(BaseSpacer), {
          size: "4",
          class: "mobile-hidden"
        })
      ]))
    : _createCommentVNode("", true)
}
}

})