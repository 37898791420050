import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item__title" }
const _hoisted_2 = {
  key: 0,
  class: "item__details_icon"
}
const _hoisted_3 = {
  key: 1,
  class: "item__comment"
}
const _hoisted_4 = ["v-if", "value"]

import { computed, onMounted } from 'vue';

// components
import { BaseText, BaseImage } from '@/components/partials';
import { BaseGrid, BaseColumn } from '@/components/primitives';

//helpers
import { colorMap } from '@/helpers/productList';

// types
import type { PropType, ComputedRef } from 'vue';
import type { ProductItem } from '@/types/product';
import type { ActionColor } from '@/types/color';

// keys
import { actionColors } from '@/types/color';

// @use
import useClassMap from '@/@use/classMap';

// constants
import ResourceStatuses from '@/constants/statuses/statusMeta';
import { ProductStatus, ProductStockStatus } from '@/constants/statuses/productStatuses';
import store from '@/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductListItem',
  props: {
  product: {
    required: true,
    type: Object as PropType<ProductItem>,
  },
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string): boolean => actionColors.includes(value),
    default: 'default',
  },
  removable: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const resourceStatuses = new ResourceStatuses();

const props = __props;
const onChange = async (e: any) => {
  await store.dispatch('returnOrder/updateItemComment', { comment: e.target.value, id: props.product?.id });
};
const { colorClass } = useClassMap(props, { color: colorMap });
const productIsInactive: ComputedRef<boolean> = computed(
  () => props.product?.productStatus === ProductStatus.INACTIVE
);

const group: string = productIsInactive.value ? 'product' : 'productStockStatus';
const statusId: number | undefined = productIsInactive.value
  ? props.product?.productStatus
  : props.product?.stockStatus;

const productStockStatusText: ComputedRef<string | undefined> = computed(() =>
  !statusId || statusId === ProductStockStatus.INSTOCK ? undefined : resourceStatuses.getText(group, statusId)
);
const productStockStatusColor: ComputedRef<string | undefined> = computed(() =>
  productStockStatusText.value && statusId ? resourceStatuses.getColor(group, statusId) : undefined
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["product-list__item", [_unref(colorClass)]]),
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('list-item-clicked', __props.product, productStockStatusText.value, productStockStatusColor.value)))
  }, [
    (productStockStatusText.value)
      ? (_openBlock(), _createBlock(_unref(BaseText), {
          key: 0,
          tag: "p",
          class: _normalizeClass(["item__status", `item__status_${productStockStatusColor.value}`])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(productStockStatusText.value), 1)
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(BaseGrid), { gap: "0" }, {
      default: _withCtx(() => [
        _createVNode(_unref(BaseColumn), {
          span: {
          default: '3',
          tablet: '3',
        },
          class: "item__image"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseImage), {
              image: {
            url: `${__props.product?.image}`,
            height: '1000',
            width: '1000',
            title: `${__props.product?.name} image`,
          }
            }, null, 8, ["image"])
          ]),
          _: 1
        }),
        _createVNode(_unref(BaseColumn), {
          span: {
          default: '9',
          tablet: '9',
        },
          class: "item__details item__details_active"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(BaseText), {
                tag: "p",
                size: {
              default: 'sm-alt',
              tablet: 'md',
            },
                bold: true,
                ref: "productName"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.product?.name), 1)
                ]),
                _: 1
              }, 512),
              (__props.product?.sku)
                ? (_openBlock(), _createBlock(_unref(BaseText), {
                    key: 0,
                    tag: "p",
                    size: {
              default: 'sm-alt',
              tablet: 'md',
            },
                    ref: "productSku"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("#" + _toDisplayString(__props.product?.sku), 1)
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true),
              (__props.product?.text)
                ? (_openBlock(), _createBlock(_unref(BaseText), {
                    key: 1,
                    tag: "p",
                    bold: true,
                    size: {
              default: 'sm-alt',
              tablet: 'md',
            },
                    ref: "productText",
                    class: "item__details_text"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.product?.text), 1)
                    ]),
                    _: 1
                  }, 512))
                : _createCommentVNode("", true)
            ]),
            (__props.removable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "trash-alt",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('delete-item', __props.product?.id)), ["stop"]))
                  })
                ]))
              : _createCommentVNode("", true),
            (__props.product?.text)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("textarea", {
                    class: "item__textarea",
                    "v-if": __props.product.text === 'Missing parts',
                    placeholder: "Enter item's description, that will be sent to Customer Service",
                    name: "comment",
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    value: __props.product?.comment,
                    onChange: onChange
                  }, null, 40, _hoisted_4)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}
}

})