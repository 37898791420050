import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]



// types
import type { PropType, ButtonHTMLAttributes } from 'vue';
import type { ButtonType } from '@/types/button';

// keys
import { buttonTypes } from '@/types/button';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonDefault',
  props: {
  type: {
    required: false,
    type: String as PropType<ButtonHTMLAttributes['type']>,
    default: 'button',
  },
  buttonDisabled: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _mergeProps({ type: __props.type }, _ctx.$attrs, { disabled: __props.buttonDisabled }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16, _hoisted_1))
}
}

})