import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form__group" }
const _hoisted_2 = ["type", "name", "id", "placeholder", "disabled", "aria-disabled"]
const _hoisted_3 = ["type", "accept", "value", "name", "id", "placeholder", "checked", "disabled", "aria-disabled"]
const _hoisted_4 = ["for"]

import { computed } from 'vue';

// components
import { BaseError } from '@/components/partials/forms';

// keys
import { inputTypes } from '@/types/form';

// types
import type { PropType, ComputedRef } from 'vue';
import type { InputType } from '@/types/form';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseInput',
  props: {
  name: {
    required: true,
    type: String as PropType<string>,
  },
  label: {
    required: true,
    type: String as PropType<string>,
  },
  type: {
    required: false,
    default: 'text',
    type: String as PropType<InputType>,
    validator: (value: string) => {
      return inputTypes.includes(value);
    },
  },
  value: {
    required: false,
    type: String as PropType<string> | boolean as PropType<boolean>,
  },
  namePrefix: {
    required: false,
    type: String as PropType<string>,
  },
  error: {
    required: false,
    type: String as PropType<string>,
  },
  accept: {
    required: false,
    type: String as PropType<string>,
  },
  multiple: {
    required: false,
    type: Boolean as PropType<boolean>,
  },
  styled: {
    required: false,
    type: Boolean as PropType<boolean>,
  },
  checked: {
    required: false,
    type: Boolean as PropType<boolean>,
  },
  disabled: {
    required: false,
    type: Boolean as PropType<boolean>,
  },
},
  emits: ["update:value", "update:checkbox", "reset:errors", "update:image"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const id: ComputedRef<string> = computed(() =>
  props.namePrefix ? `${props.namePrefix}-${props.name}` : props.name
);

const isTextArea: ComputedRef<boolean> = computed(() => props.type === 'textarea');

const updateValue: (event: Event) => void = (event: Event) => {
  emit('update:value', (event.target as HTMLInputElement).value);
};

const resetValue: () => void = () => {
  emit('reset:errors');
};
const onChange: (event: Event) => void = (event: Event) => {
  if (props.type === 'checkbox') emit('update:checkbox', event.target?.checked);
  else emit('update:image', (event.target as HTMLInputElement).files);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isTextArea.value)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 0,
          type: __props.type,
          class: "form__input form__input--textarea",
          value: "",
          name: __props.name,
          id: id.value,
          placeholder: __props.label,
          disabled: __props.disabled,
          "aria-disabled": __props.disabled,
          onKeyup: _cache[0] || (_cache[0] = ($event: any) => (updateValue($event))),
          onKeydown: _cache[1] || (_cache[1] = ($event: any) => (resetValue()))
        }, null, 40, _hoisted_2))
      : (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: __props.type,
          accept: __props.accept,
          class: _normalizeClass(['form__input', { 'form__input--checkbox': __props.type === 'checkbox' }]),
          value: __props.value,
          name: __props.name,
          id: id.value,
          placeholder: __props.label,
          multiple: true,
          checked: __props.type === 'checkbox' ? __props.checked : false,
          disabled: __props.disabled,
          "aria-disabled": __props.disabled,
          onKeyup: _cache[2] || (_cache[2] = ($event: any) => (updateValue($event))),
          onKeydown: _cache[3] || (_cache[3] = ($event: any) => (resetValue())),
          onChange: onChange
        }, null, 42, _hoisted_3)),
    _createElementVNode("label", {
      for: id.value,
      class: _normalizeClass(['form__input-label', { 'form__input-label--checkbox': __props.type === 'checkbox' }])
    }, [
      (__props.type !== 'checkbox')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createTextVNode(_toDisplayString(__props.label), 1)
          ], 64))
        : _createCommentVNode("", true)
    ], 10, _hoisted_4),
    (__props.error)
      ? (_openBlock(), _createBlock(_unref(BaseError), { key: 2 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(__props.error), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})