import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { storeBase, BaseState, StoreType } from '@/helpers/store/base';
import { RootState } from '@/store';
import router from '@/router';

// classes
import { WopsOrder, WopsOrderInterface } from '@/classes/order';
import { WopsGift, WopsGiftInterface } from '@/classes/gift';

// helpers
import {
  getOutgoingOrderItems,
  getCompleteOutgoingProcessRequestData,
  isGiftResource,
  redirectToStep,
} from '@/helpers/outgoingOrder';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';

// use
import useOrder from '@/@use/order';

// types
import type { ApiResource } from '@/types/resource';
import type { OrderProcessType } from '@/types/orderProcess';
import type { ProductItem } from '@/types/product';
import orderProcessTypes from '@/constants/orderPriority/orderProcessTypes';
import { OrderStatus } from '@/constants/statuses/orderStatuses';
import {
  CreateShipmentRequest,
  OutgoingItemMode,
  OutgoingProcessRequest,
  CompleteOutgoingProcessAction,
} from '../types/outgoingOrder';

interface OutgoingOrderState {
  item: WopsOrderInterface | WopsGiftInterface;
  orderItems: ProductItem[];
  type: OrderProcessType | null;
  parcels: number;
  oversized: boolean;
  deliveryLabel: string | null;
  deliveryLabelCourier: string | null;
  deliveryLabelFailed: boolean;
  giftLabelWarningAckownledged: boolean;
}

const { baseTypes, baseState, baseActions, baseMutations, baseGetters } = storeBase<
  RootState,
  OutgoingOrderState
>();

const types: StoreType = {
  ...(baseTypes as StoreType),
  CLEAR_STATE: 'CLEAR_STATE',
  SET_OUTGOING_ITEM: 'SET_OUTGOING_ITEM',
  SET_OUTGOING_ORDER_ITEMS: 'SET_OUTGOING_ORDER_ITEMS',
  SET_ORDER_PROCESS_TYPE: 'SET_ORDER_PROCESS_TYPE',
  SET_ITEM_DETAILS: 'SET_ITEM_DETAILS',
  SET_DELIVERY_LABEL: 'SET_DELIVERY_LABEL',
  SET_DELIVERY_LABEL_FAILED: 'SET_DELIVERY_LABEL_FAILED',
  SET_DELIVERY_LABEL_COURIER: 'SET_DELIVERY_LABEL_COURIER',
  // temp
  SET_GIFT_LABEL_WARNING_STATUS: 'SET_GIFT_LABEL_WARNING_STATUS',
};

const defaultState = (): OutgoingOrderState => ({
  ...(baseState as BaseState),
  item: {},
  orderItems: [],
  type: null,
  parcels: 1,
  oversized: false,
  deliveryLabel: null,
  deliveryLabelFailed: false,
  deliveryLabelCourier: null,
  giftLabelWarningAckownledged: false,
});

const state = defaultState();

const { giftCode } = useOrder();

const actions: ActionTree<OutgoingOrderState, RootState> = {
  ...(baseActions as unknown as ActionTree<OutgoingOrderState, RootState>),

  async sendRequest({ dispatch }, requestType: string): Promise<void> {
    await dispatch('sendApiRequest', async () => await dispatch(requestType));
  },
  async getOutgoingItemDetails({ commit, dispatch }, type: OrderProcessType): Promise<void> {
    await dispatch('page/clearMessages', '', { root: true });
    commit(types.CLEAR_STATE);
    commit(types.SET_ORDER_PROCESS_TYPE, type);
    dispatch('sendRequest', 'getPriorityItem');
  },

  async getAssignedItem({ commit, dispatch }, orderProcess: OrderProcessType): Promise<void> {
    commit(types.SET_LOADING, true);

    let response: ApiResource;
    const storedItem: string | null = localStorage.getItem(`${orderProcess}-item`);
    const storedMode: string | null = localStorage.getItem(`${orderProcess}-item-mode`);

    if (storedItem) {
      try {
        if (storedMode === OutgoingItemMode.GIFT) {
          response = await this.$wacc.gifts.get(storedItem, {
            params: {
              with: 'user',
            },
          });
        } else {
          response = await this.$wacc.orders.get(storedItem, {
            params: {
              with: 'lines.productVariant.product.assets.transforms;shippingManifest.shippingService;user;gift',
            },
          });
        }

        commit(types.SET_ORDER_PROCESS_TYPE, orderProcess);
        commit(types.SET_OUTGOING_ITEM, response.data);
        dispatch('page/setReference', state.item.reference, { root: true });

        if (!isGiftResource(state.item)) {
          commit(
            types.SET_OUTGOING_ORDER_ITEMS,
            getOutgoingOrderItems(response.data as WopsOrderInterface, state.type as OrderProcessType)
          );

          const storedParcels: number | null = parseInt(
            localStorage.getItem(`${orderProcess}-item-${state.item.id}-parcels`) ?? '1'
          );
          const storedOversized: boolean | null =
            localStorage.getItem(`${orderProcess}-item-${state.item.id}-oversized`) === 'true';

          commit(types.SET_ITEM_DETAILS, {
            parcels: storedParcels ?? 1,
            oversized: storedOversized ?? false,
          });
        }

        if (state.item.isGift) {
          const giftCode: string | null | undefined = isGiftResource(state.item)
            ? state.item.code
            : (state.item as WopsOrderInterface).gift?.code ?? '';
          const message = `Gift order: code ${giftCode}`;
          dispatch('page/addMessage', message, { root: true });
        } else if (!state.item.isGift && state.item.isFirstOrder) {
          dispatch('page/addMessage', `New Customer`, { root: true });
        }
      } catch (error: unknown) {
        dispatch('page/setPageErrors', error, { root: true });
      } finally {
        commit(types.SET_LOADING, false);
        await redirectToStep(state.item);
      }
    } else {
      await router.push({ name: orderProcess === OrderProcessTypes.PICKING ? 'Picking' : 'Packing' });
    }
  },

  async getPriorityItem({ state, commit, dispatch }): Promise<void> {
    commit(types.SET_LOADING, true);

    try {
      const response: ApiResource = await this.$wacc.orders.getPriorityOrder({
        params: {
          type: state.type,
        },
      });
      commit(types.SET_OUTGOING_ITEM, response.data);
      if (!isGiftResource(state.item)) {
        dispatch('page/setReference', state.item.reference, { root: true });
        commit(
          types.SET_OUTGOING_ORDER_ITEMS,
          getOutgoingOrderItems(response.data as WopsOrderInterface, state.type as OrderProcessType)
        );
      } else {
        dispatch('page/setReference', state.item.code, { root: true });
      }
    } catch (errors) {
      dispatch('page/setPageErrors', errors, { root: true });
      commit(types.CLEAR_STATE);
    } finally {
      commit(types.SET_LOADING, false);
    }
  },

  updateOutgoingItemDetails({ commit }, data: CreateShipmentRequest): void {
    commit(types.SET_ITEM_DETAILS, data);

    localStorage.setItem(`${state.type}-item-${state.item.id}-parcels`, data.parcels.toString());
    localStorage.setItem(`${state.type}-item-${state.item.id}-oversized`, data.oversized.toString());
  },

  updateOutgoingProductDetails({ commit, state }, data: ProductItem): void {
    const products = [...state.orderItems];
    const productToUpdate = products.find((product: ProductItem) => product?.id === data.id);

    if (!productToUpdate) return;

    productToUpdate.color = data.color;
    productToUpdate.status = data.status;
    productToUpdate.message = data.message;

    if (!localStorage.getItem(`${state.type}-item-${state.item.id}-orderLine-${productToUpdate.id}`)) {
      localStorage.setItem(
        `${state.type}-item-${state.item.id}-orderLine-${productToUpdate.id}`,
        JSON.stringify({
          color: data.color,
          status: data.status,
          message: data.message,
        })
      );
    }

    commit(types.SET_OUTGOING_ORDER_ITEMS, products);
  },

  async unassignItem({ rootState, dispatch, commit }, orderProcess: OrderProcessType): Promise<void> {
    const assigneeId: string = rootState.auth.user.id;
    commit(types.SET_LOADING, true);

    try {
      const response: ApiResource = await this.$wacc.orders.unassignOrder(
        assigneeId,
        { itemId: state.item.id },
        {
          params: {
            mode: isGiftResource(state.item) ? OutgoingItemMode.GIFT : OutgoingItemMode.ORDER,
            type:
              orderProcess === orderProcessTypes.PICKING
                ? orderProcessTypes.PICKING
                : orderProcessTypes.PACKING,
          },
        }
      );

      // Remove all local storage relaticing to assigned order
      localStorage.removeItem(`${orderProcess}-item-${state.item.id}`);
      localStorage.removeItem(`${orderProcess}-item-mode`);
      state.orderItems.forEach((item: ProductItem) => {
        localStorage.removeItem(`${orderProcess}-item-${state.item.id}-orderLine-${item.id}`);
      });
      localStorage.removeItem(`${orderProcess}-item-${state.item.id}-parcels`);
      localStorage.removeItem(`${orderProcess}-item-${state.item.id}-oversized`);

      await dispatch('page/clearMessages', '', { root: true });

      await router.push({ name: orderProcess === OrderProcessTypes.PICKING ? 'Picking' : 'Packing' });
    } catch (error) {
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },

  async assignItem({ commit, rootState, dispatch }, orderProcess: OrderProcessType): Promise<void> {
    const assignee_id: string | undefined = rootState.auth.user.id;
    commit(types.SET_LOADING, true);

    try {
      const response: ApiResource = await this.$wacc.orders.assignOrders(
        assignee_id,
        { orders: [state.item.id] },
        {
          params: {
            type: orderProcess === OrderProcessTypes.PICKING ? 'picking' : 'packing',
            mode: isGiftResource(state.item) ? OutgoingItemMode.GIFT : OutgoingItemMode.ORDER,
            with: 'lines.productVariant.product.assets.transforms;shippingManifest.shippingService;user;assignee',
          },
        }
      );

      commit(types.SET_OUTGOING_ITEM, (response.data as Array<WopsOrderInterface | WopsGiftInterface>)[0]);

      if (state.item.id) {
        localStorage.setItem(`${orderProcess}-item`, state.item.id);
        localStorage.setItem(
          `${orderProcess}-item-mode`,
          isGiftResource(state.item) ? OutgoingItemMode.GIFT : OutgoingItemMode.ORDER
        );
      }

      await router.push({
        name: orderProcess === OrderProcessTypes.PICKING ? 'PickingProducts' : 'PackingProducts',
      });

      if (state.item.isGift) {
        const giftCode: string | null | undefined = isGiftResource(state.item)
          ? state.item.code
          : (state.item as WopsOrderInterface).gift?.code ?? '';
        const message = `Gift order: code ${giftCode}`;
        dispatch('page/addMessage', message, { root: true });
      } else if (!state.item.isGift && state.item.isFirstOrder) {
        dispatch('page/addMessage', 'New customer', { root: true });
      }
    } catch (error: unknown) {
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },

  async completeOutgoingProcess(
    { commit, dispatch },
    { type, binLocation }: CompleteOutgoingProcessAction
  ): Promise<void> {
    const isGift: boolean = isGiftResource(state.item);
    const createShipmentRequestData: CreateShipmentRequest = {
      parcels: state.parcels,
      oversized: state.oversized,
    };
    const completeProcessRequestData: OutgoingProcessRequest = getCompleteOutgoingProcessRequestData(
      state.item,
      binLocation,
      state.orderItems
    );

    commit(types.SET_ORDER_PROCESS_TYPE, type);
    commit(types.SET_LOADING, true);
    let deliveryLabelFailed = false;
    try {
      if (type === OrderProcessTypes.PACKING && !isGift && !state.deliveryLabelFailed) {
        deliveryLabelFailed = true;
        await this.$wacc.couriers.createShipment(state.item?.id, createShipmentRequestData);
        commit(types.SET_DELIVERY_LABEL_FAILED, false);
      } else if (
        isGift &&
        type === OrderProcessTypes.PACKING &&
        !state.deliveryLabelFailed &&
        !state.giftLabelWarningAckownledged
      ) {
        commit(types.SET_GIFT_LABEL_WARNING_STATUS, true);
        throw {
          response: {
            data: {
              errors: {
                message: [
                  `This order needs to be sent by Royal Mail. Please give to your supervisor with reference: ${state.item.code}`,
                ],
              },
            },
          },
        };
      }
      const response: ApiResource = await this.$wacc.orders.completeOutgoingOrder(
        completeProcessRequestData,
        {
          params: {
            type: state.type,
            mode: isGift ? OutgoingItemMode.GIFT : OutgoingItemMode.ORDER,
          },
        }
      );

      commit(types.SET_OUTGOING_ITEM, response.data);

      // Reset all stored order/gift information and redirect back to start of packing flow if order/gift is now on-hold
      if (
        (state.item.statusId === OrderStatus.ON_HOLD_PACKING && type === OrderProcessTypes.PACKING) ||
        type === OrderProcessTypes.PICKING ||
        isGift
      ) {
        // Remove all local storage relaticing to assigned order
        localStorage.removeItem(`${type}-item`);
        localStorage.removeItem(`${type}-item-mode`);
        state.orderItems.forEach((item: ProductItem) => {
          localStorage.removeItem(`${type}-item-${state.item.id}-orderLine-${item.id}`);
        });

        commit(types.CLEAR_STATE);
        await dispatch('page/clearMessages', '', { root: true });

        await router.push({ name: type === OrderProcessTypes.PICKING ? 'Picking' : 'Packing' });
      } else {
        await router.push({ name: 'PackingPrintLabel' });
      }
    } catch (error: unknown) {
      if (deliveryLabelFailed === true) {
        commit(types.SET_DELIVERY_LABEL_FAILED, true);
      }
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },

  setDeliveryLabelFailed({ commit }, deliveryLabelFailed: boolean): void {
    commit(types.SET_DELIVERY_LABEL_FAILED, deliveryLabelFailed);
  },

  async getDeliveryLabel({ commit, dispatch }): Promise<void> {
    commit(types.SET_LOADING, true);

    try {
      const response: ApiResource = await this.$wacc.couriers.printOrder(state.item.id);

      commit(types.SET_DELIVERY_LABEL, response.data);
      commit(types.SET_DELIVERY_LABEL_COURIER, response.meta.apiCourier);
    } catch (error: unknown) {
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },
};

const mutations: MutationTree<OutgoingOrderState> = {
  ...(baseMutations as unknown as MutationTree<OutgoingOrderState>),
  [types.SET_OUTGOING_ITEM](state: OutgoingOrderState, item: WopsOrderInterface | WopsGiftInterface) {
    state.item = isGiftResource(item)
      ? new WopsGift(item as WopsGiftInterface)
      : new WopsOrder(item as WopsOrderInterface);
  },
  [types.SET_OUTGOING_ORDER_ITEMS](state: OutgoingOrderState, orderItems: ProductItem[]) {
    state.orderItems = orderItems;
  },
  [types.CLEAR_STATE](state: OutgoingOrderState) {
    Object.assign(state, defaultState());
  },
  [types.SET_ORDER_PROCESS_TYPE](state: OutgoingOrderState, type: OrderProcessType) {
    state.type = type;
  },
  [types.SET_ITEM_DETAILS](state: OutgoingOrderState, data: CreateShipmentRequest) {
    state.parcels = data.parcels;
    state.oversized = data.oversized;
  },
  [types.SET_DELIVERY_LABEL](state: OutgoingOrderState, data: string) {
    state.deliveryLabel = data;
  },
  [types.SET_DELIVERY_LABEL_FAILED](state: OutgoingOrderState, data: boolean) {
    state.deliveryLabelFailed = data;
  },
  [types.SET_DELIVERY_LABEL_COURIER](state: OutgoingOrderState, data: string) {
    state.deliveryLabelCourier = data;
  },
  [types.SET_GIFT_LABEL_WARNING_STATUS](state: OutgoingOrderState, status: boolean) {
    state.giftLabelWarningAckownledged = status;
  },
};

const getters: GetterTree<OutgoingOrderState, RootState> = {
  ...(baseGetters as unknown as GetterTree<OutgoingOrderState, RootState>),

  getOutgoingOrderItemsBySortedBrand: (state: OutgoingOrderState): ProductItem[] => {
    return state.orderItems.sort((orderItemA: ProductItem, orderItemB: ProductItem) => {
      return (orderItemA.brand as string).localeCompare(orderItemB.brand as string);
    });
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  getters,
};
