import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { storeBase, BaseState, StoreType } from '@/helpers/store/base';
import store, { RootState } from '@/store';
import type { ApiResource } from '@/types/resource';

import type BaseUserInterface from 'whirli-client/src/Interfaces/user/User';
import WopsUser, { WopsUserInterface } from '@/classes/user';
import type { ProductItem } from '@/types/product';
import type { ReturnOrderRequest } from '@/types/returnOrder';

import router from '@/router';
import { StockStatus } from '@/constants/statuses/returnsStatuses';
import { getReturnOrderItems, getToyboxProductItems, createRequestParams } from '@/helpers/returnOrders';
import { capitalizeFirstLetterOfString } from '@/helpers/strings';
import ResourceStatuses from '@/constants/statuses/statusMeta';
import type { UnexpectedItemForm } from '@/types/form';

const { baseTypes, baseState, baseGetters, baseActions, baseMutations } = storeBase<
  ReturnOrderState,
  RootState
>();

const resourceStatuses = new ResourceStatuses();

const types: StoreType = {
  ...(baseTypes as StoreType),
  SET_RETURN_ORDER_ITEMS: 'SET_RETURN_ORDER_ITEMS',
  EDIT_RETURN_ORDER_ITEM: 'EDIT_RETURN_ORDER_ITEM',
  SET_TOYBOX_ITEMS: 'SET_TOYBOX_ITEMS',
  EDIT_TOYBOX_ITEM: 'EDIT_TOYBOX_ITEM',
  SET_USER: 'SET_USER',
  CLEAR_STATE: 'CLEAR_STATE',
  CLEAR_LOCAL_STORAGE: 'CLEAR_LOCAL_STORAGE',
  SET_USER_ID: 'SET_USER_ID',
  SET_STATUS: 'SET_STATUS',
  SET_IS_COMPLETE: 'SET_IS_COMPLETE',
  SET_TOYBOX_STATUS: 'SET_TOYBOX_STATUS',
  REMOVE_TOYBOX_STATUS: 'REMOVE_TOYBOX_STATUS',
  SET_COMMENT: 'SET_COMMENT',
  SET_IMAGES: 'SET_IMAGES',
  DELETE_IMAGE: 'DELETE_IMAGE',
};

export interface UpdateReturnOrderLineRequest {
  orderLines: Array<string>;
}

interface ReturnOrderState extends BaseState {
  returnOrderItems: ProductItem[];
  returnOrderItem: ProductItem;
  toyboxItems: ProductItem[];
  toyboxItem: ProductItem;
  user: WopsUserInterface;
  comment: string;
  images: File[] | null;
  userId: string | null;
  isComplete: boolean;
}

const defaultState = (): ReturnOrderState => ({
  ...(baseState as BaseState),
  user: {},
  userId: null,
  returnOrderItems: [],
  returnOrderItem: {},
  toyboxItems: [],
  toyboxItem: {},
  isComplete: false,
  comment: '',
  images: null,
});

const state = defaultState();

const actions: ActionTree<ReturnOrderState, RootState> = {
  ...(baseActions as ActionTree<ReturnOrderState, RootState>),
  initStoreWithUser({ commit, dispatch, state }, searchResults): void {
    commit(types.CLEAR_LOCAL_STORAGE);
    commit(types.CLEAR_STATE);
    searchResults.forEach((searchResult: WopsUserInterface) => {
      if (searchResult.activeReturnOrder?.length) {
        commit(types.SET_USER, searchResult);
        dispatch('setActiveReturnItems');
        dispatch('setToyboxItems');
        router.push({ name: 'Returns', params: { user_id: state.user.id as string } });
      } else if (searchResult.toybox) {
        commit(types.SET_USER, searchResult);
        dispatch('setToyboxItems');
        router.push({ name: 'Toybox', params: { user_id: state.user.id as string } });
      }
      dispatch('page/setReference', searchResult.reference, { root: true });
    });
  },
  initStoreWithOrder({ commit, dispatch, state }, searchResults): void {
    commit(types.CLEAR_LOCAL_STORAGE);
    commit(types.CLEAR_STATE);
    commit(types.SET_USER, searchResults[0].user);
    const hasActiveReturn: boolean = searchResults[0].user.activeReturnOrder?.length;

    if (hasActiveReturn) {
      dispatch('setActiveReturnItems');
    }
    dispatch('setToyboxItems');

    router.push({
      name: hasActiveReturn ? 'Returns' : 'Toybox',
      params: { user_id: state.user.id as string },
    });
  },
  async sendRequest({ dispatch }, requestType: string): Promise<void> {
    await dispatch('sendApiRequest', async () => await dispatch(requestType));
  },
  async getUserWithActiveReturn({ commit, dispatch, state }): Promise<void> {
    const withActiveReturnOrder =
      'activeReturnOrder.returnOrderLines.stock.productVariant.product.assets.transforms;toybox.productVariant.product.assets.transforms';
    const response: ApiResource = await this.$wacc.users.get(state.userId, {
      params: { with: withActiveReturnOrder },
    });
    commit(types.SET_USER, response.data as BaseUserInterface);
    dispatch('page/setReference', (response.data as BaseUserInterface).reference, { root: true });
    dispatch('setActiveReturnItems');
    dispatch('setToyboxItems');
  },
  async processReturn({ state, dispatch }): Promise<void> {
    const action: string = state.user.activeReturnOrder?.length ? 'completeReturn' : 'createReturn';
    await dispatch('sendRequest', action);
  },
  async completeReturn({ state, getters, dispatch }): Promise<void> {
    if (state.user.activeReturnOrder && state.user.activeReturnOrder[0]) {
      const completeReturn = getters.getCompleteReturnRequest;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      await this.$wacc.returnOrders.complete(state.user.activeReturnOrder[0].id, completeReturn, config);
      if (Object.keys(state.errors).length === 0) {
        await dispatch('setIsComplete', true);
      }
    }
  },
  async createReturn({ getters, state, dispatch }): Promise<void> {
    const createReturnRequest: Partial<ReturnOrderRequest> = getters.getCreateReturnRequest;
    await this.$wacc.returnOrders.create(createReturnRequest);
    await dispatch('setIsComplete', true);
    if (Object.keys(state.errors).length === 0) {
      await dispatch('setIsComplete', true);
    }
  },
  async refreshUser({ commit, state, dispatch }, userId: string): Promise<void> {
    if (Object.keys(state.user).length === 0) {
      commit(types.SET_USER_ID, userId);
      await dispatch('sendRequest', 'getUserWithActiveReturn');
    }
  },
  setActiveReturnItems({ commit, state }): void {
    if (state.user.activeReturnOrder && state.user.activeReturnOrder[0]) {
      const storedData = localStorage.getItem('inbound-returnOrderItems');
      const returnOrderItems = storedData
        ? JSON.parse(storedData)
        : getReturnOrderItems(state.user.activeReturnOrder[0]);
      commit(types.SET_RETURN_ORDER_ITEMS, returnOrderItems);
    }
  },
  async setToyboxItems({ commit, state }): Promise<void> {
    if (state.user.toybox) {
      const storedItems = localStorage.getItem('inbound-toyboxItems');
      const toyboxItems = storedItems ? JSON.parse(storedItems) : getToyboxProductItems(state.user.toybox);
      commit(types.SET_TOYBOX_ITEMS, toyboxItems);

      // Check if on load any exist in localStorage
      const comment: string | null = localStorage.getItem('inbound-comment');
      const images: string | null = localStorage.getItem('inbound-images');

      if (comment) {
        commit(types.SET_COMMENT, comment);
      }

      if (images) {
        const parsed = JSON.parse(images);
        const blob: Blob = await fetch(parsed.image).then((res) => res.blob());
        commit(types.SET_IMAGES, [blob]);
      }
    }
  },
  editReturnOrderItem({ commit, state }, returnOrderItemId: string): void {
    const returnOrderItem = state.returnOrderItems.find((item: ProductItem) => item.id === returnOrderItemId);
    commit(types.EDIT_RETURN_ORDER_ITEM, returnOrderItem);
    router.push({
      name: 'Return',
      params: { user_id: state.user.id as string, toy_id: returnOrderItem?.id as string },
    });
  },
  editToyboxItem({ commit, state }, ToyboxItemId: string): void {
    const toyboxItem = state.toyboxItems.find((item: ProductItem) => item.id === ToyboxItemId);
    commit(types.EDIT_TOYBOX_ITEM, toyboxItem);
    router.push({
      name: 'Toybox Toy',
      params: { user_id: state.user.id as string, toy_id: toyboxItem?.id as string },
    });
  },
  setReturnStatus({ commit, state }, status: StockStatus): void {
    commit(types.SET_STATUS, status);
    localStorage.setItem('inbound-returnOrderItems', JSON.stringify(state.returnOrderItems));
    router.push({ name: 'Returns', params: { user_id: state.user.id as string } });
  },
  setIsComplete({ commit, state }, status: boolean): void {
    commit(types.SET_IS_COMPLETE, status);
    if (!state.isComplete) {
      router.push({ name: 'Inbound' });
      commit(types.CLEAR_STATE);
    }
  },
  setActiveToyboxToyStatus({ commit, state }, status: StockStatus): void {
    commit(types.SET_TOYBOX_STATUS, status);
    localStorage.setItem('inbound-toyboxItems', JSON.stringify(state.toyboxItems));
    router.push({ name: 'Returns', params: { user_id: state.user.id as string } });
  },
  removeActiveToyboxToyStatus({ commit }, ToyboxItemId: string): void {
    const toyBoxItem = state.toyboxItems.find((item: ProductItem) => item.id === ToyboxItemId);
    commit(types.REMOVE_TOYBOX_STATUS, toyBoxItem);
    localStorage.setItem('inbound-toyboxItems', JSON.stringify(state.toyboxItems));
  },
  submitUnexpectedItemForm({ commit, state, dispatch }, unexpectedItemForm: UnexpectedItemForm): void {
    if (typeof unexpectedItemForm.comment === 'string') {
      commit(types.SET_COMMENT, unexpectedItemForm.comment);
      localStorage.setItem('inbound-comment', state.comment as string);
    }
    commit(types.SET_IMAGES, unexpectedItemForm.images);
    dispatch('handleLocalStorageImages');
    router.push({ name: 'Returns', params: { user_id: state.user.id as string } });
  },
  updateItemComment({ commit, state }, item: { id: string; comment: string }): void {
    state.returnOrderItems = state.returnOrderItems.map((returnItem: ProductItem) =>
      returnItem.id === item.id ? { ...returnItem, comment: item.comment } : { ...returnItem }
    );
    state.toyboxItems = state.toyboxItems.map((returnItem: ProductItem) =>
      returnItem.id === item.id ? { ...returnItem, comment: item.comment } : { ...returnItem }
    );
    const returnOrderItems = state.toyboxItems.map((returnItem: ProductItem) =>
      returnItem.id === item.id ? { ...returnItem, comment: item.comment } : { ...returnItem }
    );
    commit(types.EDIT_RETURN_ORDER_ITEM, returnOrderItems);
  },
  deleteUnexpectedItemImage({ commit, state }, unexpectedItemImageId: string): void {
    commit(types.DELETE_IMAGE, unexpectedItemImageId);
  },
  async handleLocalStorageImages({ state }): Promise<void> {
    if (state.images) {
      const b64 = await encodeImage(state.images[0] as Blob);
      const jsonString = JSON.stringify({ image: b64 });
      localStorage.setItem('inbound-images', jsonString);
    } else {
      localStorage.removeItem('inbound-images');
    }
  },
};

const mutations: MutationTree<ReturnOrderState> = {
  ...(baseMutations as MutationTree<ReturnOrderState>),
  [types.SET_USER](state: ReturnOrderState, user: WopsUserInterface) {
    Object.assign(state, defaultState());
    state.user = new WopsUser(user);
  },
  [types.SET_USER_ID](state: ReturnOrderState, id: string): void {
    state.userId = id;
  },
  [types.SET_RETURN_ORDER_ITEMS](state: ReturnOrderState, returnOrderItems: ProductItem[]): void {
    state.returnOrderItems = returnOrderItems;
  },
  [types.SET_TOYBOX_ITEMS](state: ReturnOrderState, toyboxItems: ProductItem[]): void {
    state.toyboxItems = toyboxItems;
  },
  [types.EDIT_RETURN_ORDER_ITEM](state: ReturnOrderState, returnOrderItem: ProductItem): void {
    state.returnOrderItem = returnOrderItem;
  },
  [types.EDIT_TOYBOX_ITEM](state: ReturnOrderState, toyboxItem: ProductItem): void {
    state.toyboxItem = toyboxItem;
  },
  [types.SET_STATUS](state: ReturnOrderState, status: StockStatus): void {
    state.returnOrderItem.status = status;
    state.returnOrderItem.color = resourceStatuses.getColor('returns', status);
    state.returnOrderItem.text = capitalizeFirstLetterOfString(resourceStatuses.getText('returns', status));
    state.returnOrderItems.map((item: ProductItem) => {
      if (item.id === state.returnOrderItem.id) {
        item = state.returnOrderItem;
      }
    });
  },
  [types.SET_TOYBOX_STATUS](state: ReturnOrderState, status: StockStatus): void {
    state.toyboxItem.status = status;
    state.toyboxItem.color = resourceStatuses.getColor('returns', status);
    state.toyboxItem.text = capitalizeFirstLetterOfString(resourceStatuses.getText('returns', status));
    state.toyboxItems.map((item: ProductItem) => {
      if (item.id === state.toyboxItem.id) {
        item = state.toyboxItem;
      }
    });
  },
  [types.REMOVE_TOYBOX_STATUS](state: ReturnOrderState, toyBoxItem: ProductItem): void {
    delete toyBoxItem.status;
    delete toyBoxItem.color;
    delete toyBoxItem.text;
    state.toyboxItems = state.toyboxItems.map((item: ProductItem): ProductItem => {
      return item.id === toyBoxItem.id ? toyBoxItem : item;
    });
  },
  [types.SET_IS_COMPLETE](state: ReturnOrderState, status: boolean): void {
    state.isComplete = status;
  },
  [types.CLEAR_STATE](state: ReturnOrderState): void {
    Object.assign(state, defaultState());
  },
  [types.CLEAR_LOCAL_STORAGE](): void {
    localStorage.removeItem('inbound-returnOrderItems');
    localStorage.removeItem('inbound-toyboxItems');
    localStorage.removeItem('inbound-comment');
    localStorage.removeItem('inbound-images');
  },
  [types.SET_COMMENT](state: ReturnOrderState, comment: string): void {
    state.comment = comment;
  },
  [types.SET_IMAGES](state: ReturnOrderState, images: File[] | null): void {
    state.images = images;
  },
  [types.DELETE_IMAGE](state: ReturnOrderState, imageId: string): void {
    state.images?.splice(+imageId, 1) as File[];
    if (state.images?.length === 0) {
      state.images = null;
      state.comment = '';
      localStorage.removeItem('inbound-comment');
      localStorage.removeItem('inbound-images');
    }
  },
};

const encodeImage = (image: Blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = function () {
      resolve(reader.result);
    };
  });
};

const getters: GetterTree<ReturnOrderState, RootState> = {
  ...(baseGetters as GetterTree<ReturnOrderState, RootState>),
  getCompleteReturnRequest: (state: ReturnOrderState, getters): FormData => {
    const processedReturns = state.returnOrderItems.concat(getters.getProcessedToyboxItems);
    return createRequestParams(processedReturns, state.comment, state.images);
  },
  getCreateReturnRequest: (state: ReturnOrderState, getters): FormData => {
    const formData = createRequestParams(getters.getProcessedToyboxItems, state.comment, state.images);
    formData.append('charge_customer', JSON.stringify(1));
    formData.append('user_id', state.user.id as string);
    return formData;
  },
  getProcessedToyboxItems: (state: ReturnOrderState): ProductItem[] => {
    return state.toyboxItems.filter((item: ProductItem) => {
      return item.status && item.status !== StockStatus.WITH_CUSTOMER;
    });
  },
  canSubmitReturn: (state: ReturnOrderState): boolean => {
    let check = true;
    state.returnOrderItems.forEach((returnItem: ProductItem) => {
      if (!returnItem.status) {
        check = false;
      }
    });
    return check;
  },
  getProcessedReturnCount: (state: ReturnOrderState): number => {
    return state.returnOrderItems.reduce((acc: number, cur: ProductItem) => (cur.status ? ++acc : acc), 0);
  },
  getUnexpectedProducts: (state: ReturnOrderState): Record<string, string>[] => {
    const items: Record<string, string>[] = [];
    state.images?.forEach((image: File | null) => {
      items.push({
        id: items.length.toString(),
        image: URL.createObjectURL(image),
      });
    });
    return items;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
