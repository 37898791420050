import { createStore } from 'vuex';
import { _RouteLocationBase } from 'vue-router';
import { Auth } from '../../index';
import auth from '@/store/modules/auth';
import search from '@/store/modules/search';
import outgoingOrder from '@/store/modules/outgoingOrder';
import returnOrder from '@/store/modules/returnOrder';
import product from '@/store/modules/product';
import bin from '@/store/modules/bin';
import page from '@/store/modules/page';

export default createStore({
  modules: {
    auth,
    search,
    outgoingOrder,
    returnOrder,
    product,
    bin,
    page,
  },
});

export interface RootState {
  auth: Auth;
  route: _RouteLocationBase;
}
