import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';

// helpers
import { defaultSizes, sizeMap, colorMap } from '@/helpers/text';

//keys
import { tags } from '@/types/text';
import { actionColors } from '@/types/color';

// types
import type { PropType, ComputedRef } from 'vue';
import type { BreakpointTextSize, Tag } from '@/types/text';
import type { ActionColor } from '@/types/color';

// @use
import useClassMap from '@/@use/classMap';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseText',
  props: {
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string): boolean => actionColors.includes(value),
    default: 'default',
  },
  tag: {
    required: false,
    type: String as PropType<Tag>,
    validator: (value: string): boolean => tags.includes(value),
    default: 'span',
  },
  size: {
    required: false,
    type: Object as PropType<BreakpointTextSize>,
    default: (): BreakpointTextSize => {
      return {
        default: defaultSizes.default,
        tablet: defaultSizes.tablet,
      };
    },
  },
  bold: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const sizeClass: ComputedRef<string[]> = computed((): string[] => {
  const defaultSize: string = props.size.default || defaultSizes.default;
  const tabletSize: string = props.size.tablet || props.size.default || defaultSizes.tablet; //This will take default into account for tablet so we don't have to set it twice
  return [sizeMap['default'][defaultSize], sizeMap['tablet'][tabletSize]];
});

const { colorClass } = useClassMap({ color: props.color }, { color: colorMap });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), {
    class: _normalizeClass([sizeClass.value, _unref(colorClass), { bold: __props.bold }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})