import { RouteRecordRaw } from 'vue-router';
const packingRoutes: Array<RouteRecordRaw> = [
  {
    path: '/packing',
    name: 'Packing',
    component: () => import(/* webpackChunkName: "packing" */ '../views/packing/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Packing',
    },
  },

  {
    path: '/packing/products',
    name: 'PackingProducts',
    component: () => import(/* webpackChunkName: "packing-products" */ '../views/packing/products/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Packing Products',
    },
  },

  {
    path: '/packing/products/:product',
    name: 'PackingProduct',
    component: () =>
      import(/* webpackChunkName: "packing-products-:product" */ '../views/packing/products/_product.vue'),
    meta: {
      requiresAuth: true,
      title: 'Packing Products',
    },
  },

  {
    path: '/packing/confirm',
    name: 'PackingConfirm',
    component: () => import(/* webpackChunkName: "confirm-pack" */ '../views/packing/confirm/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Confirm Pack',
    },
  },

  {
    path: '/packing/print-label',
    name: 'PackingPrintLabel',
    component: () =>
      import(/* webpackChunkName: "packing-print-label" */ '../views/packing/print-label/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Print Order Label',
    },
  },
];

export default packingRoutes;
