import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



// helpers
import { colorMap } from '@/helpers/alert';

// types
import type { ActionColor } from '@/types/color';
import type { PropType } from 'vue';

// @use
import useClassMap from '@/@use/classMap';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAlert',
  props: {
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string) => Object.keys(colorMap).includes(value),
    default: 'default',
  },
},
  setup(__props) {

const props = __props;

const { colorClass } = useClassMap(props, { color: colorMap });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", _unref(colorClass)])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})