export const actionColors = [
  'default',
  'default-alt',
  'default-dark',
  'success',
  'warning',
  'warning-alt',
  'danger',
  'white',
  'white-secondary',
];
export type ActionColor = typeof actionColors[number];

export interface ColorMap {
  [key: string]: string;
}
