import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

// components
import { BaseContainer, BaseSection, BaseMain, BaseAside } from '@/components/primitives';
import { BaseSpacer, Notification } from '@/components/partials';

// types
import type { ComputedRef } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BasePageWrapper',
  setup(__props) {

const store = useStore();
const router = useRouter();
const route = useRoute();

const message: ComputedRef<string> = computed(() => store.getters['page/getMessage']);
const messageBeCareful: ComputedRef<string> = computed(() => store.getters['page/getMessageCareFul']);
const messageColor: ComputedRef<string> = computed(() => store.state.page.messagesColor);
const errors: ComputedRef<Record<string, Array<string> | void>> = computed(
  () => store.state.page.errors ?? {}
);
const hasErrors: ComputedRef<boolean> = computed(() => Object.keys(errors.value).length > 0);

watch(errors, () => {
  if (hasErrors.value) {
    setTimeout(async () => {
      window.scroll({ top: 0, behavior: 'smooth' });
    }, 1000);
  }
});

router.beforeResolve((to): void => {
  store.dispatch('page/clearErrors', {}, { root: true });
});

router.afterEach((to): void => {
  store.dispatch('page/setAppName', route.path, { root: true });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(BaseAside), null, {
      default: _withCtx(() => [
        (message.value !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["page-message", `page-message_${messageColor.value}`])
            }, _toDisplayString(message.value), 3))
          : _createCommentVNode("", true),
        (messageBeCareful.value !== '')
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["page-message", `page-message_danger`])
            }, _toDisplayString(messageBeCareful.value), 1))
          : _createCommentVNode("", true),
        (hasErrors.value)
          ? (_openBlock(), _createBlock(_unref(BaseContainer), { key: 2 }, {
              default: _withCtx(() => [
                _createVNode(_unref(BaseSpacer), { size: "1" }),
                _createVNode(_unref(BaseSection), { gutter: "0" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Notification), {
                      messages: errors.value,
                      color: "danger"
                    }, null, 8, ["messages"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_unref(BaseMain), null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    })
  ], 64))
}
}

})