import type { WopsOrderLineInterface } from '../../classes/orderLine';
import type { OrderProcessType } from '../../types/orderProcess';

export enum OutgoingItemMode {
  GIFT = 'gift',
  ORDER = 'order',
}

export interface OutgoingProcessRequest {
  itemId: string;
  orderLines?: Pick<WopsOrderLineInterface, 'id' | 'statusId' | 'message'>[];
  binLocation?: string;
}

export interface CreateShipmentRequest {
  parcels: number;
  oversized: boolean;
}

export interface CompleteOutgoingProcessAction {
  type: OrderProcessType;
  binLocation?: string;
}
