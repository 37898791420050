import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import { computed } from 'vue';

// types
import type { PropType, ComputedRef } from 'vue';
import type { ButtonLinkData } from '@/types/button';
import type { Link } from '@/types/link';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonLink',
  props: {
  url: {
    required: true,
    type: String as PropType<string>,
  },
},
  setup(__props) {

const props = __props;

const isInternal: ComputedRef<boolean> = computed((): boolean => {
  return props.url.startsWith('/');
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (isInternal.value)
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        to: __props.url
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: __props.url,
        target: "_blank",
        rel: "nofollow noopener"
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
}
}

})