import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form__group" }
const _hoisted_2 = ["for"]

import { computed } from 'vue';

// types
import type { PropType, ComputedRef } from 'vue';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'StandAloneLabel',
  props: {
  name: {
    required: true,
    type: String as PropType<string>,
  },
  namePrefix: {
    required: false,
    type: String as PropType<string>,
  },
},
  setup(__props) {

const props = __props;

const id: ComputedRef<string> = computed(() =>
  props.namePrefix ? `${props.namePrefix}-${props.name}` : props.name
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: id.value,
      class: "form__input-label"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_2)
  ]))
}
}

})