import humps from 'humps';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import router from '@/router';
import { useRoute } from 'vue-router';
const route = useRoute();
// @Todo: This contains some temp any types that have to be reviewed and removed.
const decamelizeWithFiles = (object: any): any => {
  if (object && !(object instanceof File)) {
    if (Array.isArray(object)) {
      return object.map((item) => decamelizeWithFiles(item));
    }
    if (object instanceof FormData) {
      const formData = new FormData();
      for (const [key, value] of (object as any).entries(object)) {
        formData.append(humps.decamelize(key), value);
      }
      return formData;
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [humps.decamelize(next)]: decamelizeWithFiles(object[next]),
        }),
        {}
      );
    }
  }
  return object;
};
interface AxiosExtra extends Partial<AxiosInstance> {
  [k: string]: any;
  $request<T = unknown, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R>;
  $get<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  $delete<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  $head<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  $options<T = unknown, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  $post<T = unknown, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  $put<T = unknown, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
  $patch<T = unknown, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

const axiosExtra: Partial<AxiosExtra> = {};

for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
  axiosExtra['$' + method] = function (...args: string[]) {
    return this[method](...args).then((res: AxiosResponse) => res && res.data);
  };
}

const extendAxiosInstance = (axios: Partial<AxiosExtra>) => {
  for (const key in axiosExtra) {
    axios[key] = axiosExtra[key].bind(axios);
  }
};

const getToken = () => localStorage.getItem('token');

const instance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

extendAxiosInstance(instance);

instance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    config.data = decamelizeWithFiles(config.data);
    config.headers['Authorization'] = `Bearer ${getToken()}`;

    // @Todo: $log augmentation in index.d.ts not currently working
    // Vue.$log.debug('Request config ', config)
    return config;
  },
  function (error: AxiosError) {
    return handleAxiosError(error);
  }
);
instance.interceptors.response.use(
  function (response: AxiosResponse) {
    response.data = humps.camelizeKeys(response.data);
    // @Todo: $log augmentation in index.d.ts not currently working
    // Vue.$log.debug('Response', response);
    return response;
  },
  function (error: AxiosError) {
    return handleAxiosError(error);
  }
);

function handleAxiosError(error: AxiosError): void | Promise<void> {
  const currentPath: string = '';
  const res = error.response;
  if (res?.status === 401) {
    if (res?.config?.url) {
      if (res?.config?.url.includes('login')) {
        return;
      }
      store.dispatch('auth/sendRequest', 'logout');
    }
    router.push({ name: 'Login', query: { redirect: currentPath } });
  } else {
    return Promise.reject(error);
  }
}
export default instance;
