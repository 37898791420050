import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

// component
import BaseSpacer from '@/components/partials/BaseSpacer/BaseSpacer.vue';

// helper
import { sizeMap } from '@/helpers/spacer';
import { colorMap } from '@/helpers/section';

// types
import type { PropType, ComputedRef } from 'vue';
import type { Size } from '@/types/spacer';
import type { ActionColor } from '@/types/color';

import useClassMap from '@/@use/classMap';

// define props
type ExtendedActionColor = 'light';

// @use

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSection',
  props: {
  gutter: {
    required: false,
    type: String as PropType<Size>,
    validator: (value: string) => Object.keys(sizeMap).includes(value),
    default: '4',
  },
  color: {
    required: false,
    type: String as PropType<ActionColor | ExtendedActionColor>,
    validator: (value: ActionColor | ExtendedActionColor) => Object.keys(colorMap).includes(value),
    default: 'default',
  },
},
  setup(__props) {

const props = __props;

const { colorClass } = useClassMap(props, { color: colorMap });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([_unref(colorClass)])
  }, [
    _createVNode(BaseSpacer, { size: __props.gutter }, null, 8, ["size"]),
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(BaseSpacer, { size: __props.gutter }, null, 8, ["size"])
  ], 2))
}
}

})