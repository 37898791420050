import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { storeBase, BaseState, StoreType } from '@/helpers/store/base';
import { RootState } from '@/store';
import router from '@/router';
import { AuthParams } from '@/types/auth';
import User from 'whirli-client/src/Interfaces/user/User';

const { baseTypes, baseState, baseActions, baseMutations, baseGetters } = storeBase<AuthState, RootState>();

const types: StoreType = {
  ...(baseTypes as StoreType),
  SET_AUTH_USER: 'SET_AUTH_USER',
};

interface AuthState extends BaseState {
  user: Partial<User>;
}

const state = (): AuthState => ({
  ...(baseState as BaseState),
  user: {},
});

const actions: ActionTree<AuthState, RootState> = {
  ...(baseActions as ActionTree<AuthState, RootState>),

  async initialiseStore({ dispatch }): Promise<void> {
    // check if token exists and add auth state
    if (localStorage.getItem('token')) {
      await dispatch('sendRequest', 'refreshUser');
    }
  },

  async sendRequest({ dispatch }, type: string): Promise<void> {
    await dispatch('sendApiRequest', async () => await dispatch(type));
  },

  async login({ commit }, params: Required<AuthParams>): Promise<void> {
    commit(types.SET_LOADING, true);
    try {
      const response = await this.$auth.login({ email: params.email, password: params.password });
      localStorage.setItem('token', response.data.token.accessToken);
      commit(types.SET_AUTH_USER, response.data.user);
      router.push({ name: 'Dashboard' });
      return response;
    } catch (error) {
      return error.response.data;
    } finally {
      commit(types.SET_LOADING, false);
    }
  },

  async logout({ commit }): Promise<void> {
    try {
      await this.$auth.logout();
      localStorage.removeItem('token');
      commit(types.SET_AUTH_USER, {});
      await router.push({ name: 'Login' });
    } catch (error) {
      // @Todo: If we have toaster notifications or similar would be useful
    }
  },

  async refreshUser({ commit }): Promise<void> {
    const response = await this.$auth.user();
    if (response.data?.user) {
      commit(types.SET_AUTH_USER, response.data.user);
    }
  },
};

const mutations: MutationTree<AuthState> = {
  ...(baseMutations as MutationTree<AuthState>),
  [types.SET_AUTH_USER](state, user): void {
    state.user = user;
  },
};

const getters: GetterTree<AuthState, RootState> = {
  ...(baseGetters as GetterTree<AuthState, RootState>),
  getId: (state: AuthState): string | undefined => {
    return state.user?.id;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
