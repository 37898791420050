//types
import type { ProductItem, ProductAssociation } from '@/types/product';
import type { WopsOrderInterface } from '@/classes/order';
import type { WopsProductInterface } from '@/classes/product';
import type { WopsGiftInterface } from '@/classes/gift';
import type { WopsOrderLineInterface } from '../classes/orderLine';
import type BaseOrderLineInterface from 'whirli-client/dist/Interfaces/order/OrderLine';

// helpers
import { getFirstImageAsset, ProductAssetsKindByCategory } from '@/helpers/asset';
import { noAssociationAvailable } from '@/helpers/product';

// constants
import type { OrderProcessType } from '../types/orderProcess';
import { OutgoingProcessRequest } from '@/store/types/outgoingOrder';
import { OrderStatus } from '@/constants/statuses/orderStatuses';
import router from '@/router';
import { AssociationClasses } from '@/constants/products/productAssociations';
import { AssetKind, AssetDocumentCategory } from '@/constants/products/assets';

export function isGiftResource(item: WopsOrderInterface | WopsGiftInterface): boolean {
  return !!item?.code;
}

export function getOutgoingOrderItems(
  outgoingOrder: WopsOrderInterface,
  orderProcess: OrderProcessType
): ProductItem[] {
  const outgoingOrderLines: BaseOrderLineInterface[] = outgoingOrder.lines || [];
  const getOutgoingOrderItems: ProductItem[] = outgoingOrderLines.flatMap((line: BaseOrderLineInterface) => {
    let storedData: Record<string, string | number> = {};

    if (localStorage.getItem(`${orderProcess}-item-${outgoingOrder.id}-orderLine-${line.id}`)) {
      const storage: Record<string, string | number> = JSON.parse(
        localStorage.getItem(`${orderProcess}-item-${outgoingOrder.id}-orderLine-${line.id}`) as string
      );

      storedData = {
        color: storage.color,
        status: storage.status,
        message: storage.message,
      };
    }

    return {
      id: line?.id,
      name: line?.productVariant?.product?.name,
      image: getFirstImageAsset(line?.productVariant?.product as WopsProductInterface),
      sku: line?.productVariant?.sku,
      slug: line?.productVariant?.product?.slug,
      color: storedData.color ?? 'default',
      status: storedData.status ?? undefined,
      message: storedData.message ?? line.message,
      brand: getProductBrand(
        line?.productVariant?.product?.productAssociations as ProductAssociation[] | undefined
      ),
      instuctionsPDFs: ProductAssetsKindByCategory(
        line?.productVariant?.product as WopsProductInterface,
        AssetKind.DOCUMENT,
        AssetDocumentCategory.INSTRUCTIONS
      ),
    } as ProductItem;
  });
  return getOutgoingOrderItems;
}

export function getProductBrand(associations: ProductAssociation[] | undefined): string {
  return (
    associations?.find(
      (association: ProductAssociation) => association.associationClass === AssociationClasses.BRAND
    )?.name ?? noAssociationAvailable(AssociationClasses.BRAND)
  );
}

export function getCompleteOutgoingProcessRequestData(
  item: WopsOrderInterface | WopsGiftInterface,
  binLocation?: string,
  orderItems?: ProductItem[]
): OutgoingProcessRequest {
  const request: Record<string, unknown> = { itemId: item.id ?? '', binLocation };

  if (orderItems) {
    request.orderLines =
      orderItems.map((item: ProductItem) => {
        const orderLineItem: Pick<WopsOrderLineInterface, 'id' | 'statusId' | 'message'> = {
          id: item.id ?? '',
          statusId: item.status ?? undefined,
          message: item?.message as string,
        };

        if (orderLineItem.statusId) {
          delete orderLineItem.message;
        }

        return orderLineItem;
      }) ?? [];
  }

  return request as unknown as OutgoingProcessRequest;
}

export function getOrderReferenceString(orderReference: string): string {
  return `Order #${orderReference}`;
}

export async function redirectToStep(item: WopsGiftInterface | WopsOrderInterface): Promise<void> {
  switch (true) {
    case item.statusId === OrderStatus.PACKED && !isGiftResource(item) && item.shippingManifest:
      await router.push({ name: 'PackingPrintLabel' });
      break;
    case item.statusId === OrderStatus.PACKED && !isGiftResource(item) && !item.shippingManifest:
      await router.push({ name: 'Packing' });
      break;
  }
}
