import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-dropdown-list" }

import { PropType } from 'vue';

// components
import { DropDownListItem } from '@/components/features';
import { BaseText } from '../../partials/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDownList',
  props: {
  items: {
    required: true,
    type: Array as PropType<Array<string>>,
  },
},
  emits: ['item:click'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.items, (item, index) => {
      return (_openBlock(), _createBlock(_unref(DropDownListItem), {
        key: index,
        item: item,
        "onItem:click": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('item:click', $event)))
      }, null, 8, ["item"]))
    }), 128))
  ]))
}
}

})