import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { BaseState, storeBase, StoreType } from '@/helpers/store/base';
import { RootState } from '@/store';

import type { ApiResource } from '@/types/resource';
import type { SearchForm } from '@/types/form';
import SearchRequestBuilder from '@/helpers/store/search-request-builder';
import { SearchTypes } from '@/constants/searchTypes';
import SearchRequestParams from '@/types/search';

const { baseTypes, baseState, baseGetters, baseActions, baseMutations } = storeBase<
  GlobalSearchState,
  RootState
>();

const types: StoreType = {
  ...(baseTypes as StoreType),
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
  CLEAR_SEARCH: 'CLEAR_SEARCH',
  SET_MESSAGE: 'SET_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
};

const requestBuilder: SearchRequestBuilder = new SearchRequestBuilder();

interface GlobalSearchState extends BaseState {
  results: Array<Record<string, unknown>>;
  type: string;
  index: string;
  message: string | null;
}

const state = (): GlobalSearchState => ({
  ...(baseState as BaseState),
  results: [],
  type: 'global',
  index: '',
  message: null,
});

const actions: ActionTree<GlobalSearchState, RootState> = {
  ...(baseActions as ActionTree<GlobalSearchState, RootState>),
  async sendRequest({ dispatch }, form: SearchForm): Promise<void> {
    await dispatch('sendApiRequest', async () => await dispatch('search', form));
  },
  async search({ commit, dispatch, getters }, form: SearchForm): Promise<void> {
    commit(types.CLEAR_MESSAGE);
    commit(types.SET_SEARCH_TYPE, form.searchType);
    commit(types.SET_SEARCH, form.searchTerm);

    if (form.searchType === SearchTypes.SEARCH_TYPE_INBOUND) {
      commit(types.SET_MATCH, form.searchTerm);
    }

    const params = getters.getRequestParams;
    const response: ApiResource = await this.$wacc.search.all({ params });

    if (response.data.length === 0) {
      commit(types.SET_MESSAGE, 'No results found.');
      return;
    }
    const action: string = form.searchTerm.includes('USR') ? 'initStoreWithUser' : 'initStoreWithOrder';
    switch (true) {
      case form.searchType === SearchTypes.SEARCH_TYPE_INBOUND:
        dispatch(`returnOrder/${action}`, response.data, { root: true });
        break;
      case form.searchType === SearchTypes.SEARCH_TYPE_PRODUCT:
        dispatch('product/initialiseStore', response.data, { root: true });
        break;
    }
  },
};

const mutations: MutationTree<GlobalSearchState> = {
  ...(baseMutations as MutationTree<GlobalSearchState>),
  [types.SET_SEARCH_RESULTS](state: GlobalSearchState, searchResults: Array<Record<string, unknown>>): void {
    state.results = searchResults;
  },
  [types.SET_SEARCH_TYPE](state: GlobalSearchState, type: string): void {
    state.type = type;
  },
  [types.SET_MESSAGE](state: GlobalSearchState, message: string): void {
    state.message = message;
  },
  [types.CLEAR_SEARCH](state: GlobalSearchState): void {
    (state.results = []), (state.message = null);
  },
  [types.CLEAR_MESSAGE](state: GlobalSearchState): void {
    state.message = null;
  },
};

const getters: GetterTree<GlobalSearchState, RootState> = {
  ...(baseGetters as GetterTree<GlobalSearchState, RootState>),
  getRequestParams: (state: GlobalSearchState, getters) => {
    switch (state.type) {
      case SearchTypes.SEARCH_TYPE_INBOUND:
        return getters.getInboundSearchParams;
      default:
        return getters.getGlobalSearchParams;
    }
  },
  getInboundSearchParams: (state: GlobalSearchState): Partial<SearchRequestParams> => {
    const withUser = state.params.search?.includes('ORD') ? 'user.' : '';
    const relations = `${withUser}activeReturnOrder.returnOrderLines.stock.productVariant.product.assets.transforms;${withUser}toybox.productVariant.product.assets.transforms`;
    return {
      with: relations,
      search: state.params.search,
      perPage: 20,
      indexes: state.params.search?.includes('USR') ? 'users' : 'orders',
      match: `reference:${state.params.match}`,
    };
  },
  getGlobalSearchParams: (state: GlobalSearchState) => {
    return {
      search: state.params.search,
      perPage: 30,
    };
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
