import { ProductStatus, ProductStockStatus } from '../productStatuses';
import StatusMeta from '@/types/status';

const productMeta: Array<StatusMeta> = [
  {
    id: ProductStatus.ACTIVE,
    text: 'active',
  },
  {
    id: ProductStatus.ACTIVE_OBSOLETE,
    text: 'active absolete',
  },
  {
    id: ProductStatus.INACTIVE,
    text: 'Inactive',
    color: 'default',
  },
];

export default productMeta;

export const productStockMeta: Array<StatusMeta> = [
  {
    id: ProductStockStatus.INSTOCK,
    text: 'In stock',
    color: 'success',
  },
  {
    id: ProductStockStatus.LOWSTOCK,
    text: 'Low stock',
    color: 'warning',
  },
  {
    id: ProductStockStatus.OUTOFSTOCK,
    text: 'Out of stock',
    color: 'danger',
  },
];
