import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-search-suggestions" }

import { useStore } from 'vuex';

// containers
import { BaseForm, BaseInput, BaseError } from '@/components/partials/forms';
import { BaseGrid, BaseColumn } from '../../primitives/index';
import { BaseSpacer, LoadingSpinner, BaseButton, BaseText } from '../../partials/index';
import { DropDownList } from '@/components/features';

// helpers
import { getFormError } from '../../../helpers/form';
// @ts-ignore
import debounce from 'lodash.debounce';

// types
import { ComputedRef, reactive, computed, watch, onUnmounted } from 'vue';
import { SuggestSearchForm } from '@/types/form';

// setup

export default /*@__PURE__*/_defineComponent({
  __name: 'SearchSuggestions',
  setup(__props) {

const store = useStore();

// reactive
const state = reactive({
  debouncer: 0,
  searchForm: {
    search: '',
  },
});

// watchers
const searchTermWatcher = watch(
  () => state.searchForm.search,
  () => {
    clearTimeout(state.debouncer);
    const debouncer = debounce(suggestSearch, 0);
    state.debouncer = window.setTimeout(() => debouncer(), 500, {
      leading: true,
      trailing: false,
    });
  },
  { immediate: false }
);

// store[state]
const suggestedProducts: ComputedRef<string[]> = computed(() => store.state.product.suggestedProducts);

// store[actions]
const suggestSearch: () => Promise<void> = async () => {
  if (!state.searchForm.search) {
    clearSuggestedProducts();
    return;
  }

  const params: SuggestSearchForm = {
    search: state.searchForm.search,
  };

  await store.dispatch('product/suggestProduct', params);
};
const clearSuggestedProducts = () => store.dispatch('product/clearSuggestedProducts');

// store[getters]
// @Todo: Change path to fetch errors from page store module
const errors: ComputedRef<Record<string, string[]>> = computed(() => store.getters['product/getStoreErrors']);
const loading: ComputedRef<boolean> = computed(() => store.getters['product/getStoreLoading']);

// functions
const clearSearch = () => {
  state.debouncer = 0;
};

// lifecycles
onUnmounted((): void => {
  clearSuggestedProducts();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BaseForm), { class: "v-search-suggestions__form" }, {
      default: _withCtx(() => [
        (loading.value)
          ? (_openBlock(), _createBlock(_unref(LoadingSpinner), {
              key: 0,
              class: "v-search-suggestions__form-loader",
              size: "small"
            }))
          : _createCommentVNode("", true),
        _createVNode(_unref(BaseGrid), null, {
          default: _withCtx(() => [
            _createVNode(_unref(BaseColumn), { span: { tablet: '6' } }, {
              default: _withCtx(() => [
                (errors.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_unref(BaseError), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(getFormError)(errors.value)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(BaseSpacer), { size: "1" })
                    ], 64))
                  : _createCommentVNode("", true),
                _createVNode(_unref(BaseInput), {
                  label: "Search product name",
                  name: "search-term",
                  value: state.searchForm.search,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((state.searchForm.search) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            (suggestedProducts.value?.length)
              ? (_openBlock(), _createBlock(_unref(BaseColumn), { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(DropDownList), {
                      items: suggestedProducts.value,
                      "onItem:click": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('item:click', $event)))
                    }, null, 8, ["items"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_unref(BaseColumn), { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(BaseText), null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("No search results.")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})