import { SizeMap } from '@/types/size';
import { ColorMap } from '@/types/color';

export const sizeMap: SizeMap = {
  small: 'loading-spinner--small',
  base: 'loading-spinner--base',
  large: 'loading-spinner--large',
};

export const colorMap: ColorMap = {
  default: '',
  success: 'loading-spinner--success',
  warning: 'loading-spinner--warning',
  'warning-alt': 'loading-spinner--warning-alt',
  danger: 'loading-spinner--danger',
};
