export enum OrderStatus {
  CREATED = 1,
  PLACED = 2,
  PICKING = 8,
  ON_HOLD_PICKING = 10,
  PICKED = 9,
  PACKING = 7,
  ON_HOLD_PACKING = 11,
  PACKED = 3,
  DISPATCHED = 4,
  COMPLETED = 5,
  CANCELLED = 6,
}
