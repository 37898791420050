<template>
  <main id="main">
    <slot />
  </main>
</template>

<script lang="ts"></script>

<style lang="scss" scoped>
@include from(breakpoint('tablet')) {
  #main {
    overflow: hidden;
  }
}
</style>
