<template>
  <div class="container">
    <slot />
  </div>
</template>

<script lang="ts"></script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: $container-max;
  padding: size('1');
  margin: auto;
}

@include from(breakpoint('tablet')) {
  .container {
    max-height: 100%;
    overflow: auto;
  }
}
</style>
