import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

// helpers
import { defaultSizes, sizeMap, defaultJustify, justifyMap, defaultAlign, alignMap } from '@/helpers/column';

// types
import type { PropType, ComputedRef } from 'vue';
import type {
  BreakpointColumnSize,
  BreakpointJustifyPosition,
  BreakpointAlignPosition,
} from '@/types/column';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseColumn',
  props: {
  span: {
    required: false,
    type: Object as PropType<BreakpointColumnSize>,
    default: (): BreakpointColumnSize => {
      return {
        default: defaultSizes.default,
        tablet: defaultSizes.tablet,
      };
    },
  },
  justify: {
    required: false,
    type: Object as PropType<BreakpointJustifyPosition>,
    default: (): BreakpointJustifyPosition => {
      return {
        default: defaultJustify.default,
        tablet: defaultJustify.tablet,
      };
    },
  },
  align: {
    required: false,
    type: Object as PropType<BreakpointAlignPosition>,
    default: (): BreakpointAlignPosition => {
      return {
        default: defaultAlign.default,
        tablet: defaultAlign.tablet,
      };
    },
  },
},
  setup(__props) {

const props = __props;

const spanClass: ComputedRef<string[]> = computed((): string[] => {
  const defaultSize: string = props.span.default || defaultSizes.default;
  const tabletSize: string = props.span.tablet || props.span.default || defaultSizes.tablet; //This will take default into account for tablet so we don't have to set it twice

  const defaultJustifyPos: string = props.justify.default || defaultJustify.default;
  const tabletJustifyPos: string = props.justify.tablet || props.justify.default || defaultJustify.tablet;

  const defaultAlignPos: string = props.align.default || defaultAlign.default;
  const tabletAlignPos: string = props.align.tablet || props.align.default || defaultAlign.tablet;

  return [
    sizeMap['default'][defaultSize],
    sizeMap['tablet'][tabletSize],
    justifyMap['default'][defaultJustifyPos],
    justifyMap['tablet'][tabletJustifyPos],
    alignMap['default'][defaultAlignPos],
    alignMap['tablet'][tabletAlignPos],
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["column", [spanClass.value]])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})