import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// components
import { BaseContainer, BaseSection } from '@/components/primitives';
import { BaseButton, BaseSpacer, BaseText } from '@/components/partials';
import { ProductList } from '@/components/features';
import { PropType } from '@vue/runtime-core';

// types
import type { ComputedRef } from 'vue';
import type { WopsUserInterface } from '@/classes/user';
import type { ProductItem } from '@/types/product';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReturnTemplate',
  props: {
  editable: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const store = useStore();
const router = useRouter();


const user: ComputedRef<WopsUserInterface> = computed(() => store.state.returnOrder.user);
const comment: ComputedRef<string> = computed(() => store.state.returnOrder.comment);
const returnOrderItems: ComputedRef<ProductItem[]> = computed(() => store.state.returnOrder.returnOrderItems);
const toyboxItems: ComputedRef<ProductItem[]> = computed(
  () => store.getters['returnOrder/getProcessedToyboxItems']
);

const unexpectedProducts: ComputedRef<Record<string, string>[]> = computed(
  () => store.getters['returnOrder/getUnexpectedProducts']
);

const goToProduct = async (product: ProductItem, message?: string, messageColor?: string): Promise<void> => {
  await store.dispatch('returnOrder/editReturnOrderItem', product.id);
  setProductStockStatus(message, messageColor);
};

const goToToyboxProduct = async (
  product: ProductItem,
  message?: string,
  messageColor?: string
): Promise<void> => {
  await store.dispatch('returnOrder/editToyboxItem', product.id);
  setProductStockStatus(message, messageColor);
};

const setProductStockStatus = (message?: string, messageColor?: string): void => {
  if (message) store.dispatch('page/addMessage', message, { root: true });
  if (messageColor) store.dispatch('page/addMessageColor', messageColor, { root: true });
};

const goToToybox = async (): Promise<void> => {
  router.push({
    name: 'Toybox',
    params: { user_id: user.value.id as string },
  });
};

const goToUnexpectedItem = async (): Promise<void> => {
  router.push({
    name: 'Unexpected',
    params: { user_id: user.value.id as string },
  });
};

const removeToyboxProduct = (productId: string): void => {
  store.dispatch('returnOrder/removeActiveToyboxToyStatus', productId);
};

const deleteUnexpectedProduct = (imageId: string): void => {
  store.dispatch('returnOrder/deleteUnexpectedItemImage', imageId);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseSection), { gutter: "0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseText), {
            tag: "h2",
            size: { default: 'base-alt' },
            bold: true
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Expected returns")
            ])),
            _: 1
          }),
          _createVNode(_unref(BaseSpacer)),
          (returnOrderItems.value?.length > 0)
            ? (_openBlock(), _createBlock(_unref(ProductList), {
                key: 0,
                products: returnOrderItems.value,
                onListItemClicked: goToProduct
              }, null, 8, ["products"]))
            : (!__props.editable && returnOrderItems.value?.length === 0)
              ? (_openBlock(), _createBlock(_unref(BaseText), {
                  key: 1,
                  tag: "p",
                  size: { default: 'sm-alt' }
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" None entered ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSection), { gutter: "0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseSpacer)),
          _createVNode(_unref(BaseText), {
            tag: "h2",
            size: { default: 'base-alt' },
            bold: true
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Unexpected returns")
            ])),
            _: 1
          }),
          _createVNode(_unref(BaseSpacer)),
          (toyboxItems.value?.length > 0)
            ? (_openBlock(), _createBlock(_unref(ProductList), {
                key: 0,
                ref: "toyboxList",
                products: toyboxItems.value,
                removable: __props.editable,
                onListItemClicked: goToToyboxProduct,
                onDeleteItem: removeToyboxProduct
              }, null, 8, ["products", "removable"]))
            : _createCommentVNode("", true),
          (__props.editable)
            ? (_openBlock(), _createBlock(_unref(BaseButton), {
                key: 1,
                ref: "unexpected",
                color: "default-alt",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (goToToybox())),
                type: "submit"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Add item from customer's toybox ")
                ])),
                _: 1
              }, 512))
            : (toyboxItems.value?.length === 0)
              ? (_openBlock(), _createBlock(_unref(BaseText), {
                  key: 2,
                  tag: "p",
                  size: { default: 'sm-alt' }
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" None entered ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSection), { gutter: "0" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseSpacer)),
          _createVNode(_unref(BaseText), {
            tag: "h2",
            size: { default: 'base-alt' },
            bold: true
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Customer service flags")
            ])),
            _: 1
          }),
          _createVNode(_unref(BaseSpacer)),
          (comment.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(BaseText), {
                  tag: "p",
                  size: {
            default: 'sm-alt',
            tablet: 'md',
          }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(comment.value), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(BaseSpacer))
              ]))
            : _createCommentVNode("", true),
          (unexpectedProducts.value?.length > 0)
            ? (_openBlock(), _createBlock(_unref(ProductList), {
                key: 1,
                ref: "unexpectedProductList",
                products: unexpectedProducts.value,
                removable: __props.editable,
                onDeleteItem: deleteUnexpectedProduct
              }, null, 8, ["products", "removable"]))
            : _createCommentVNode("", true),
          (__props.editable)
            ? (_openBlock(), _createBlock(_unref(BaseButton), {
                key: 2,
                ref: "unexpectedItem",
                color: "default-alt",
                onClick: goToUnexpectedItem,
                type: "submit"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Add item ")
                ])),
                _: 1
              }, 512))
            : (unexpectedProducts.value?.length === 0)
              ? (_openBlock(), _createBlock(_unref(BaseText), {
                  key: 3,
                  tag: "p",
                  size: { default: 'sm-alt' }
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" None entered ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default", {
        user: user.value,
        returnOrderItemsLength: returnOrderItems.value.length
      })
    ]),
    _: 3
  }))
}
}

})