/**
 * Returns Status Constants
 */

export enum StockStatus {
  WITH_CUSTOMER = 2,
  ORDERED_NOT_PICKED = 9,
  PICKED_FOR_ORDER = 10,
  PACKED = 11,
  DISPATCHED = 12,
  DESIGNATED_FOR_RETURN = 6,
  RETURN_PLACED = 7,
  RETURNED_IN_TRANSIT = 13,
  RETURNED_IN_INBOUND = 14,
  RETURNED_BEING_CLEANED = 15,
  IN_WORKSHOP_DAMAGED = 18,
  IN_WORKSHOP_MISSING_PARTS = 16,
  IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS = 17,
  IN_WAREHOUSE = 1,
  SOLD = 8,
  DAMAGED = 5,
}
