import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

// helper
import { sizeMap } from '@/helpers/spacer';

// types
import type { PropType, ComputedRef } from 'vue';
import type { Size } from '@/types/spacer';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseSpacer',
  props: {
  size: {
    required: false,
    type: String as PropType<Size>,
    validator: (value: string) => Object.keys(sizeMap).includes(value),
    default: '1',
  },
},
  setup(__props) {

const props = __props;

const sizeClass: ComputedRef<string> = computed((): string => sizeMap[props.size]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["spacer", sizeClass.value])
  }, null, 2))
}
}

})