import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "product-list__items" }



// components
import { ProductListItem } from '@/components/features';

// types
import type { PropType } from 'vue';
import type { ProductItem } from '@/types/product';

export default /*@__PURE__*/_defineComponent({
  __name: 'ProductList',
  props: {
  products: {
    required: true,
    type: Array as PropType<ProductItem[]>,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.products, (product) => {
      return (_openBlock(), _createBlock(_unref(ProductListItem), _mergeProps({
        key: product?.id,
        product: product,
        color: product?.color,
        ref_for: true
      }, _ctx.$attrs), null, 16, ["product", "color"]))
    }), 128))
  ]))
}
}

})