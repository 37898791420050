import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



// components
import { PropType } from '@vue/runtime-core';
import { BaseText } from '../../partials/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'DropDownListItem',
  props: {
  item: {
    required: true,
    type: String as PropType<string>,
  },
},
  emits: ['item:click'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "v-dropdown-list-item",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('item:click', __props.item)))
  }, [
    _createVNode(_unref(BaseText), null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(__props.item), 1)
      ]),
      _: 1
    })
  ]))
}
}

})