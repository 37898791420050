import { DefaultBreakpointTextSize } from '@/types/text';
import { SizeMap } from '@/types/text';
import { ColorMap } from '@/types/color';

export const defaultSizes: DefaultBreakpointTextSize = {
  default: 'base',
  tablet: 'base',
};
// We could auto generate this but if we decide to use Purge CSS they would all be deleted
export const sizeMap: SizeMap = {
  default: {
    sm: 'text-sm',
    'sm-alt': 'text-sm-alt',
    base: 'text-base',
    'base-alt': 'text-base-alt',
    md: 'text-md',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
  },
  tablet: {
    sm: 't:text-sm',
    'sm-alt': 't:text-sm-alt',
    base: 't:text-base',
    'base-alt': 't:text-base-alt',
    md: 't:text-md',
    lg: 't:text-lg',
    xl: 't:text-xl',
    '2xl': 't:text-2xl',
    '3xl': 't:text-3xl',
    '4xl': 't:text-4xl',
  },
};

export const colorMap: ColorMap = {
  default: '',
  success: 'text--success',
  warning: 'text--warning',
  'warning-alt': 'text--warning-alt',
  danger: 'text--danger',
};
