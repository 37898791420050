import { StockStatus } from '../returnsStatuses';
import StatusMeta from '@/types/status';

const returnsMeta: Array<StatusMeta> = [
  {
    id: StockStatus.WITH_CUSTOMER,
    text: 'with customer',
  },
  {
    id: StockStatus.ORDERED_NOT_PICKED,
    text: 'ordered not picked',
  },
  {
    id: StockStatus.PICKED_FOR_ORDER,
    text: 'picked for order',
  },
  {
    id: StockStatus.PACKED,
    text: 'packed',
  },
  {
    id: StockStatus.DISPATCHED,
    text: 'dispatched',
  },
  {
    id: StockStatus.DESIGNATED_FOR_RETURN,
    text: 'designated for return',
  },
  {
    id: StockStatus.RETURN_PLACED,
    text: 'in return',
  },
  {
    id: StockStatus.RETURNED_IN_TRANSIT,
    text: 'returned in transit',
  },
  {
    id: StockStatus.RETURNED_IN_INBOUND,
    text: 'returned in inbound',
  },
  {
    id: StockStatus.RETURNED_BEING_CLEANED,
    text: 'complete & good condition',
    color: 'success',
  },
  {
    id: StockStatus.IN_WORKSHOP_DAMAGED,
    text: 'poor condition',
    color: 'danger',
  },
  {
    id: StockStatus.IN_WORKSHOP_MISSING_PARTS,
    text: 'missing parts',
    color: 'warning',
  },
  {
    id: StockStatus.IN_WORKSHOP_DAMAGED_AND_MISSING_PARTS,
    text: 'poor condition & missing parts',
    color: 'warning',
  },
  {
    id: StockStatus.IN_WAREHOUSE,
    text: 'returned',
  },
  {
    id: StockStatus.SOLD,
    text: 'sold',
  },
  {
    id: StockStatus.DAMAGED,
    text: 'damaged',
  },
];

export default returnsMeta;
