import BaseProductInterface from 'whirli-client/dist/Interfaces/product/Product';
import { WopsProductVariantInterface } from '@/classes/productVariant';
import type { WopsAssetInterface } from '@/classes/asset';

export interface WopsProductInterface extends BaseProductInterface {
  productVariants?: WopsProductVariantInterface[];
  assets?: WopsAssetInterface[];
}

export default class WopsProduct implements WopsProductInterface {
  productVariants?: WopsProductVariantInterface[];
  assets?: WopsAssetInterface[];

  constructor(page: WopsProductInterface) {
    Object.assign(this, page);
  }
}
