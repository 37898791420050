import { computed } from 'vue';

// types
import type { ComputedRef } from 'vue';

interface UseEnvironment {
  isDevelopmentMode: ComputedRef<boolean>;
}

export default function (): UseEnvironment {
  const isDevelopmentMode: ComputedRef<boolean> = computed(() => process.env.NODE_ENV === 'development');

  return {
    isDevelopmentMode,
  };
}
