import SearchRequestParams from '@/types/search';

/*
 * SearchRequestBuilder constructs & hydrates the search request filters & params
 */
export default class SearchRequestBuilder {
  public setDefaultParams(): Partial<SearchRequestParams> {
    return {
      perPage: 25,
      search: null,
      indexes: '',
      match: null,
    };
  }

  public createRequestParams(params: SearchRequestParams): Partial<SearchRequestParams> {
    return this.cleanParamsForRequest(params);
  }

  /*
   * Removes any params that are null or empty string
   */
  public cleanParamsForRequest(params: SearchRequestParams): Partial<SearchRequestParams> {
    return Object.entries(params)
      .filter(([, value]) => value != null)
      .reduce((filtered, [key, value]) => ({ ...filtered, [key]: value }), {});
  }
}
