import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { storeBase, BaseState, StoreType } from '@/helpers/store/base';
import { RootState } from '@/store';
import router from '@/router';
import type { ApiResource } from '@/types/resource';
import WopsProduct, { WopsProductInterface } from '@/classes/product';
import { WopsProductVariantInterface } from '@/classes/productVariant';
import { StockCheckRequestInterface, TransitionInWarehouseRequestInterface } from '@/store/types/product';
import SearchRequestParams from '@/types/search';

const { baseTypes, baseState, baseGetters, baseActions, baseMutations } = storeBase<
  ProductState,
  RootState
>();

const types: StoreType = {
  ...(baseTypes as StoreType),
  SET_PRODUCT: 'SET_PRODUCT',
  CLEAR_PRODUCT: 'CLEAR_PRODUCT',
  SET_SUGGESTED_PRODUCTS: 'SET_SUGGESTED_PRODUCTS',
};

interface ProductState {
  product: Partial<WopsProductInterface>;
  suggestedProducts: string[];
  with: string;
}

const state = (): ProductState => ({
  ...(baseState as BaseState),
  product: {},
  suggestedProducts: [],
  with: 'productVariants;assets.transforms',
});

const actions: ActionTree<ProductState, RootState> = {
  ...(baseActions as unknown as ActionTree<ProductState, RootState>),
  async initialiseStore({ commit }, searchResults): Promise<void> {
    commit(types.SET_PRODUCT, searchResults[0]);
    await router.push({
      name: 'Stock - Toy',
      params: { product_id: (searchResults[0] as WopsProductInterface).id ?? '' },
    });
  },
  async sendRequest({ dispatch }, requestType: string): Promise<void> {
    await dispatch('sendApiRequest', async () => await dispatch(requestType));
  },

  /** lodash.debounce must be used with this endpoint */
  async suggestProduct({ commit, dispatch }, searchTerm: string): Promise<void> {
    dispatch('page/setPageErrors', {}, { root: true });
    commit(types.SET_LOADING, true);

    let response;
    const params: Record<string, string> = {
      search: searchTerm,
    };

    try {
      response = await this.$products.suggest.all({ params });

      commit(types.SET_SUGGESTED_PRODUCTS, response.data.products);
    } catch (error) {
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },
  async getProduct({ commit }, id: string): Promise<void> {
    const response: ApiResource = await this.$wacc.products.get(id, {
      params: { with: 'productVariants;assets.transforms' },
    });
    commit(types.SET_PRODUCT, response.data as WopsProductInterface);
  },
  async transitionInWarehouse(
    { commit, dispatch },
    requestBody: TransitionInWarehouseRequestInterface
  ): Promise<void> {
    let response: ApiResource;
    dispatch('page/setPageErrors', {}, { root: true });
    commit(types.SET_LOADING, true);

    try {
      response = await this.$wacc.stock.transitionInWarehouse(requestBody);

      if (response.meta.stockCheck) {
        commit(types.SET_PRODUCT, response.data);
        await router.push({ name: 'Stock - Toy Stock Check' });
      } else {
        commit(types.CLEAR_PRODUCT);
        localStorage.removeItem('selectedBin');
        await router.push({ name: 'Stock - Search' });
      }
    } catch (error) {
      dispatch('page/setPageErrors', error, { root: true });
    } finally {
      commit(types.SET_LOADING, false);
    }
  },
  async stockCheck({ commit, dispatch }, requestBody: StockCheckRequestInterface): Promise<void> {
    let response: ApiResource;
    dispatch('page/setPageErrors', {}, { root: true });

    try {
      response = await this.$wacc.stock.stockCheck(requestBody);

      commit(types.CLEAR_PRODUCT);
      localStorage.removeItem('selectedBin');
      await router.push({ name: 'Stock - Search' });
    } catch (error) {
      dispatch('page/setPageErrors', error, { root: true });
    }
  },
  clearSuggestedProducts({ commit, dispatch }): void {
    dispatch('page/setPageErrors', {}, { root: true });
    commit(types.SET_LOADING, false);
    commit(types.SET_SUGGESTED_PRODUCTS, []);
  },
};

const mutations: MutationTree<ProductState> = {
  ...(baseMutations as unknown as MutationTree<ProductState>),
  [types.SET_PRODUCT](state: ProductState, product: WopsProductInterface): void {
    state.product = new WopsProduct(product);
  },
  [types.CLEAR_PRODUCT](state: ProductState): void {
    state.product = {};
  },
  [types.SET_SUGGESTED_PRODUCTS](state: ProductState, products: WopsProductInterface[]): void {
    const productNames: string[] = products
      .map((product: WopsProductInterface) => product.name || '')
      .filter((name: string) => name !== '');

    state.suggestedProducts = productNames;
  },
};

const getters: GetterTree<ProductState, RootState> = {
  ...(baseGetters as unknown as GetterTree<ProductState, RootState>),
  getProductVariant: (state: ProductState): WopsProductVariantInterface | null => {
    const productVariants: Array<WopsProductVariantInterface> | undefined = state.product?.productVariants;

    return productVariants ? productVariants[0] : null;
  },
  getRequestParams: (state: ProductState): Partial<SearchRequestParams> => {
    return {
      with: state.with,
    } as Partial<SearchRequestParams>;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
