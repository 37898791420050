import { ColorMap } from '@/types/color';

export const colorMap: ColorMap = {
  default: '',
  light: 'section--light',
  success: 'section--success',
  warning: 'section--warning',
  'warning-alt': 'section--warning-alt',
  danger: 'section--danger',
};
