import { Router } from 'vue-router';
import { RouteMiddlewareType } from '@/types/router';

export function createMiddleware(router: Router, middleware: RouteMiddlewareType[]): Router {
  middleware.forEach((middleware: RouteMiddlewareType) => {
    router = middleware(router);
  });

  return router;
}
