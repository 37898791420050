/**
 * Products Status Constants
 */

export enum ProductStatus {
  ACTIVE = 1,
  ACTIVE_OBSOLETE = 2,
  INACTIVE = 3,
}

export enum ProductStockStatus {
  INSTOCK = 1,
  LOWSTOCK = 2,
  OUTOFSTOCK = 3,
}
