export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function capitalizeFirstLetterOfString(text: string): string {
  return text
    .split(' & ')
    .map((string: string) => capitalizeFirstLetter(string))
    .join(' & ');
}
