import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"


import { computed } from 'vue';

// components
import BaseAlert from '@/components/partials/BaseAlert/BaseAlert.vue';

// helpers
import { colorMap } from '@/helpers/alert';

// types
import type { ActionColor } from '@/types/color';
import type { PropType } from 'vue';
import type { ComputedRef } from 'vue';
import { mapMessages } from '@/helpers/notification';
import BaseColumn from '@/components/primitives/BaseColumn/BaseColumn.vue';
import BaseGrid from '@/components/primitives/BaseGrid/BaseGrid.vue';
import BaseSpacer from '../BaseSpacer/BaseSpacer.vue';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'Notification',
  props: {
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string) => Object.keys(colorMap).includes(value),
    default: 'default',
  },
  messages: {
    required: true,
    type: Object as PropType<Record<string, void | string[]>>,
    default: () => {},
  },
  type: {
    required: false,
    type: String as PropType<string>,
    default: 'error',
  },
},
  setup(__props) {

const props = __props;

const hasMessages: ComputedRef<boolean> = computed(() => {
  return Object.keys(props.messages).length !== 0;
});

const messageKey: ComputedRef<string> = computed((): string => {
  return (props.messages.message as string[])[0] ?? '';
});

const messageList: ComputedRef<string[]> = computed((): string[] => {
  return mapMessages(props.messages);
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (hasMessages.value)
    ? (_openBlock(), _createBlock(BaseAlert, {
        key: 0,
        type: __props.type,
        color: __props.color,
        class: "notification"
      }, {
        default: _withCtx(() => [
          _createVNode(BaseGrid, { gap: "0-5" }, {
            default: _withCtx(() => [
              _createVNode(BaseColumn, { span: { default: '1' } }, {
                default: _withCtx(() => [
                  (__props.type === 'error')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: "exclamation-triangle",
                        class: "icon"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(BaseColumn, { span: { default: '11' } }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(`${messageKey.value}${messageList.value.length ? ':' : ''}`) + " ", 1),
                    (messageList.value.length)
                      ? (_openBlock(), _createBlock(BaseSpacer, {
                          key: 0,
                          size: "0-5"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messageList.value, (message, index) => {
                    return (_openBlock(), _createElementBlock("ul", { key: index }, [
                      _createElementVNode("li", null, _toDisplayString(message), 1)
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["type", "color"]))
    : _createCommentVNode("", true)
}
}

})