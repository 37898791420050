import { DefaultBreakpointColumnSize, DefaultJustifyPosition, DefaultAlignPosition } from '@/types/column';
import { SizeMap, JustifyMap, AlignMap } from '@/types/column';

export const defaultSizes: DefaultBreakpointColumnSize = {
  default: '12',
  tablet: '12',
};
// We could auto generate this but if we decide to use Purge CSS they would all be deleted
export const sizeMap: SizeMap = {
  default: {
    '1': 'span-1',
    '2': 'span-2',
    '3': 'span-3',
    '4': 'span-4',
    '5': 'span-5',
    '6': 'span-6',
    '7': 'span-7',
    '8': 'span-8',
    '9': 'span-9',
    '10': 'span-10',
    '11': 'span-11',
    '12': 'span-12',
  },
  tablet: {
    '1': 't:span-1',
    '2': 't:span-2',
    '3': 't:span-3',
    '4': 't:span-4',
    '5': 't:span-5',
    '6': 't:span-6',
    '7': 't:span-7',
    '8': 't:span-8',
    '9': 't:span-9',
    '10': 't:span-10',
    '11': 't:span-11',
    '12': 't:span-12',
  },
};

export const defaultJustify: DefaultJustifyPosition = {
  default: 'justify-start',
  tablet: 't:justify-start',
};

export const justifyMap: JustifyMap = {
  default: {
    start: 'justify-start',
    end: 'justify-end',
    between: 'justify-between',
  },
  tablet: {
    start: 't:justify-start',
    end: 't:justify-end',
    between: 't:justify-between',
  },
};

export const defaultAlign: DefaultAlignPosition = {
  default: 'align-start',
  tablet: 't:align-start',
};

export const alignMap: AlignMap = {
  default: {
    start: 'align-start',
    end: 'align-end',
    between: 'align-between',
  },
  tablet: {
    start: 't:align-start',
    end: 't:align-end',
    between: 't:justify-between',
  },
};
