import BaseGiftInterface from 'whirli-client/dist/Interfaces/gift/Gift';
import ShippingManifestInterface from 'whirli-client/src/Interfaces/delivery/ShippingManifestInterface';
import type { WopsUserInterface } from './user';

export interface WopsGiftInterface extends Omit<BaseGiftInterface, 'user'> {
  user?: WopsUserInterface;
  isGift?: boolean;
  shippingManifest?: ShippingManifestInterface;
  isFirstOrder?: boolean;
  highlight?: number;
}

export class WopsGift implements WopsGiftInterface {
  user?: WopsUserInterface;
  code?: string;
  shippingManifest?: ShippingManifestInterface;

  constructor(page: WopsGiftInterface) {
    Object.assign(this, page);
  }

  get isGift(): boolean {
    return true;
  }
}
