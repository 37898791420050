import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { storeBase, BaseState, StoreType } from '@/helpers/store/base';
import { RootState } from '@/store';
import router from '@/router';
import { useRoute } from 'vue-router';
import type { ApiResource } from '@/types/resource';
import BaseBinInterface from 'whirli-client/dist/Interfaces/bin/Bin';
import WopsBin, { WopsBinInterface } from '@/classes/bin';
import { SelectBinForm } from '@/types/form';
import { WopsProductInterface } from '@/classes/product';

const { baseTypes, baseState, baseGetters, baseActions, baseMutations } = storeBase<BinState, RootState>();
const route = useRoute();
const types: StoreType = {
  ...(baseTypes as StoreType),
  SET_BIN: 'SET_BIN',
  SET_MESSAGE: 'SET_MESSAGE',
  SET_SELECTED_BIN: 'SET_SELECTED_BIN',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  CLEAR_BIN: 'CLEAR_BIN',
};

interface BinState {
  bin: WopsBinInterface;
  selectedBin: string;
  message: string;
  // with: string; Unneeded until possibly adding a Bin get endpoint
}

const state = (): BinState => ({
  ...(baseState as BaseState),
  bin: {},
  selectedBin: '',
  message: '',
  // with: '', // Unneeded until possibly adding a Bin get endpoint
});

const actions: ActionTree<BinState, RootState> = {
  ...(baseActions as unknown as ActionTree<BinState, RootState>),
  async selectBin({ commit, rootGetters, rootState }, binForm: SelectBinForm): Promise<void> {
    if (binForm.name === '') {
      commit(types.SET_MESSAGE, 'Please choose a valid bin location');
    } else {
      commit(types.SET_SELECTED_BIN, binForm.name);
      await router.push({
        name: 'Stock - Toy',
        params: { product_id: route.params.product_id },
      });
    }
  },
};

const mutations: MutationTree<BinState> = {
  ...(baseMutations as unknown as MutationTree<BinState>),
  [types.SET_BIN](state: BinState, bin: WopsBinInterface): void {
    state.bin = new WopsBin(bin);
  },
  [types.SET_SELECTED_BIN](state: BinState, selectedBin: string): void {
    state.selectedBin = selectedBin;
    localStorage.setItem('selectedBin', selectedBin);
  },
  [types.SET_MESSAGE](state: BinState, message: string): void {
    state.message = message;
  },
};

const getters: GetterTree<BinState, RootState> = {
  ...(baseGetters as unknown as GetterTree<BinState, RootState>),
  getSelectedBin: (state: BinState): string | null => {
    if (state.selectedBin) return state.selectedBin;
    else if (localStorage.getItem('selectedBin')) return localStorage.getItem('selectedBin');
    return null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
