import { ActionTree, GetterTree, MutationTree } from 'vuex';
import SearchRequestParams from '@/types/search';
import SearchRequestBuilder from '@/helpers/store/search-request-builder';
import type { ApiResource } from '@/types/resource';
import { AxiosError } from 'axios';

const requestBuilder: SearchRequestBuilder = new SearchRequestBuilder();
export interface BaseState {
  params: Partial<SearchRequestParams>;
  loading: boolean;
  busy: boolean;
  meta: Record<string, unknown>;
  errors: Record<string, Array<string> | void>;
}

export interface Search {
  search: string;
}

export interface StoreType {
  [k: string]: string;
}

export function storeBase<ModuleState, RootState>(): Record<
  string,
  | StoreType
  | BaseState
  | Record<string, string | BaseState | ActionTree<BaseState, RootState>>
  | ActionTree<BaseState, RootState>
  | MutationTree<BaseState>
  | GetterTree<BaseState, RootState>
> {
  const baseTypes = {
    SET_ERRORS: 'SET_ERRORS',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    SET_LOADING: 'SET_LOADING',
    SET_BUSY: 'SET_BUSY',
    SET_SEARCH: 'SET_SEARCH',
    RESET_PARAMS: 'RESET_PARAMS',
    SET_META: 'SET_META',
    SET_MATCH: 'SET_MATCH',
  };

  const baseState: BaseState = {
    params: requestBuilder.setDefaultParams(),
    loading: false,
    busy: false,
    meta: {},
    errors: {},
  };

  const baseActions: ActionTree<BaseState, RootState> = {
    async sendApiRequest({ commit, dispatch, state }, callback: () => Promise<ApiResource>): Promise<void> {
      if (state.busy) {
        return;
      }
      dispatch('page/clearErrors', {}, { root: true });
      commit(baseTypes.SET_LOADING, true);
      commit(baseTypes.SET_BUSY, true);

      try {
        await callback();
      } catch (error: unknown) {
        dispatch('page/setPageErrors', error, { root: true });
      } finally {
        commit(baseTypes.SET_LOADING, false);
        commit(baseTypes.SET_BUSY, false);
      }
    },
    resetParams({ commit }): void {
      commit(baseTypes.RESET_PARAMS);
    },
  };

  const baseMutations: MutationTree<BaseState> = {
    [baseTypes.SET_LOADING](state: BaseState, loading: boolean): void {
      state.loading = loading;
    },
    [baseTypes.SET_BUSY](state: BaseState, busy: boolean): void {
      state.busy = busy;
    },
    [baseTypes.SET_SEARCH](state: BaseState, term: string): void {
      state.params.search = term;
    },
    [baseTypes.SET_META](state: BaseState, meta: Record<string, unknown>): void {
      state.meta = meta;
    },
    [baseTypes.SET_MATCH](state: BaseState, match: string): void {
      state.params.match = match;
    },
  };

  const baseGetters: GetterTree<BaseState, RootState> = {
    getStoreLoading: (state: BaseState): boolean => {
      return state.loading;
    },
    getStoreErrors: (state: BaseState): Record<string, void | string[]> => {
      return state.errors;
    },
  };

  return {
    baseTypes,
    baseState,
    baseGetters,
    baseActions,
    baseMutations,
  };
}
