import { RouteRecordRaw } from 'vue-router';

const pickingRoutes: Array<RouteRecordRaw> = [
  {
    path: '/picking',
    name: 'Picking',
    component: () => import(/* webpackChunkName: "picking" */ '../views/picking/index.vue'),
    meta: {
      requiresAuth: true,
      // developmentOnly: true,
      title: 'Picking',
    },
  },

  {
    path: '/picking/products',
    name: 'PickingProducts',
    component: () => import(/* webpackChunkName: "picking" */ '../views/picking/products/index.vue'),
    meta: {
      requiresAuth: true,
      // developmentOnly: true,
      title: 'Picking Products',
    },
  },

  {
    path: '/picking/products/:product',
    name: 'PickingProduct',
    component: () => import(/* webpackChunkName: "picking" */ '../views/picking/products/_product.vue'),
    meta: {
      requiresAuth: true,
      // developmentOnly: true,
      title: 'Picking Products',
    },
  },

  {
    path: '/picking/confirm',
    name: 'PickingConfirm',
    component: () => import(/* webpackChunkName: "picking" */ '../views/picking/confirm/index.vue'),
    meta: {
      requiresAuth: true,
      // developmentOnly: true,
      title: 'Confirm Pick',
    },
  },
];

export default pickingRoutes;
