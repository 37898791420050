import Client from 'whirli-client';
import type HttpClient from 'whirli-client/src/Interfaces/HttpClient';
import axios from '@/plugins/axios';
import type { App } from '@vue/runtime-core';
export default {
  install: (app: App): void => {
    app.config.globalProperties.$store.$wacc = new Client(axios as unknown as HttpClient).wacc;
    app.config.globalProperties.$store.$auth = new Client(axios as unknown as HttpClient).api;
    app.config.globalProperties.$store.$products = new Client(axios as unknown as HttpClient).products;
  },
};
