import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "table__loading-overlay"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "table__title" }
const _hoisted_5 = { class: "table__cell" }

import { computed } from 'vue';

// components
import { BaseText, LoadingSpinner } from '@/components/partials';

// types
import type { PropType } from 'vue';
import type { TableCell, TableRow } from '@/types/table';


export default /*@__PURE__*/_defineComponent({
  __name: 'VerticalTable',
  props: {
  rows: {
    required: true,
    type: Array as PropType<TableRow[]>,
  },
  isLoaded: {
    required: true,
    type: Boolean as PropType<boolean>,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.isLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_unref(LoadingSpinner))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.rows, (row) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: row.title.content,
          class: "table__row"
        }, [
          _createElementVNode("th", _hoisted_4, [
            _createVNode(_unref(BaseText), {
              color: row.title.color
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.title.content), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ]),
          _createElementVNode("td", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.columns, (column) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "table__row",
                key: column.content
              }, [
                _createVNode(_unref(BaseText), {
                  class: _normalizeClass(column.type === 'list' ? 'list' : ''),
                  tag: column.link ? 'a' : 'span',
                  href: column.link ? column.link : null,
                  target: column.link ? '_blank' : null,
                  rel: column.link ? 'nofollower noopener' : null,
                  color: column.color
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(column.type === 'list' ? '- ' : '') + _toDisplayString(column.content), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "tag", "href", "target", "rel", "color"])
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})