export const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'address', 'del', 'strong', 'a'];
export const sizes = ['sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', '4xl'];

export type Tag = typeof tags[number];
export type Size = typeof sizes[number];

export interface SizeMap {
  [key: string]: {
    [tag in Tag]: string;
  };
}

export interface DefaultBreakpointTextSize {
  default: Size;
  tablet: Size;
}

export interface BreakpointTextSize {
  default?: Size;
  tablet?: Size;
}
