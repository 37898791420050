import { WopsProductInterface } from '@/classes/product';
import { ProductItem } from '@/types/product';
import { getFirstImageAsset, ProductAssetsKindByCategory } from '@/helpers/asset';
import { ProductStockStatus } from '@/constants/statuses/productStatuses';
import { AssetDocumentCategory, AssetKind } from '@/constants/products/assets';

export function formatProductItem(product: WopsProductInterface): ProductItem {
  return {
    id: product.id,
    name: product?.name,
    image: getFirstImageAsset(product),
    sku: product.productVariants ? product.productVariants[0].sku : '',
    slug: product?.slug,
    instuctionsPDFs: ProductAssetsKindByCategory(
      product as WopsProductInterface,
      AssetKind.DOCUMENT,
      AssetDocumentCategory.INSTRUCTIONS
    ),
  } as ProductItem;
}

export function noAssociationAvailable(association: string): string {
  return `No ${association} available`;
}

export function getProductStockStatusId(stock: number | undefined): number {
  return stock !== undefined
    ? stock === 0
      ? ProductStockStatus.OUTOFSTOCK
      : stock < 3
      ? ProductStockStatus.LOWSTOCK
      : ProductStockStatus.INSTOCK
    : ProductStockStatus.INSTOCK;
}
