import type { WopsAssetInterface } from '@/classes/asset';
import type { WopsProductInterface } from '@/classes/product';
import { AssetKind } from '@/constants/products/assets';

export function getFirstImageAsset(product: WopsProductInterface): string {
  if (!product?.assets?.length) {
    return getStockImage();
  }

  const asset: WopsAssetInterface = product.assets[0];

  if (!asset.thumbnail?.url && asset.url) {
    return asset.url;
  }

  return asset.thumbnail?.url ?? getStockImage();
}

export function getStockImage(): string {
  return '/img/products/no-image.jpg';
}

export function ProductAssetsKindByCategory(
  product: WopsProductInterface,
  kind: keyof typeof AssetKind,
  category: number
): string[] {
  return (product?.assets
    ?.filter((asset: WopsAssetInterface) => asset.kind === kind && asset?.category === category)
    .map((asset: WopsAssetInterface) => asset.url) || []) as string[];
}
