import { RouteRecordRaw } from 'vue-router';

const stockRoutes: Array<RouteRecordRaw> = [
  {
    path: '/stock',
    name: 'Stock - Search',
    component: () => import(/* webpackChunkName: "Stock - Search" */ '../views/stock/Index.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/stock/toy/:product_id',
    name: 'Stock - Toy',
    component: () => import(/* webpackChunkName: "Stock - Toy" */ '../views/stock/toy/_toy.vue'),
    meta: {
      requiresAuth: true,
      requiresProduct: true,
    },
  },
  {
    path: '/stock/toy/:product_id/select-bin',
    name: 'Stock - Select Bin',
    component: () => import(/* webpackChunkName: "Stock - Select Bin" */ '../views/stock/toy/bin/Index.vue'),
    meta: {
      requiresAuth: true,
      requiresProduct: true,
    },
  },
  {
    path: '/stock/toy/:product_id/stock-check',
    name: 'Stock - Toy Stock Check',
    component: () =>
      import(
        /* webpackChunkName: "Stock - Replenish Toy Stock" */ '../views/stock/toy/stock-check/Index.vue'
      ),
    meta: {
      requiresAuth: true,
      requiresProduct: true,
    },
  },
];

export default stockRoutes;
