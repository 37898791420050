import { SizeMap } from '@/types/spacer';

export const sizeMap: SizeMap = {
  '0': '',
  hairline: 'spacer--hairline',
  '0-25': 'spacer--0-25',
  '0-5': 'spacer--0-5',
  '1': 'spacer--1',
  '2': 'spacer--2',
  '3': 'spacer--3',
  '4': 'spacer--4',
  '5': 'spacer--5',
  '6': 'spacer--6',
  '7': 'spacer--7',
  '8': 'spacer--8',
  '9': 'spacer--9',
  '10': 'spacer--10',
  '11': 'spacer--11',
  '12': 'spacer--12',
};
