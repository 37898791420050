import StatusMeta from '@/types/status';
import returns from './returnsMeta';
import product, { productStockMeta as productStockStatus } from './productMeta';
import { capitalizeFirstLetter } from '@/helpers/strings';

export default class ResourceStatuses {
  protected statuses: Record<string, Array<StatusMeta>>;

  constructor() {
    this.statuses = {
      returns,
      product,
      productStockStatus,
    };
  }

  getColor(group: string, statusId: number): string {
    const groupStatus: Array<StatusMeta> = this.statuses[group];

    const newStatusObj: Record<string, Record<string, string>> = (groupStatus as []).reduce(
      (acc: { [x: string]: { color: string } }, item: { id: string | number; color: string }) => {
        acc[item.id] = { color: item.color };
        return acc;
      },
      {}
    );

    return newStatusObj[statusId].color;
  }

  getText(group: string, statusId: number): string {
    const groupStatus: Array<StatusMeta> = this.statuses[group];

    const newStatusObj: Record<string, Record<string, string>> = (groupStatus as []).reduce(
      (acc: { [x: string]: { text: string } }, item: { id: string | number; text: string }) => {
        acc[item.id] = { text: item.text };
        return acc;
      },
      {}
    );

    return newStatusObj[statusId].text;
  }

  getIdByText(group: string, text: string): number {
    const groupStatus: Array<StatusMeta> = this.statuses[group];
    const newStatusObj: Record<string, Record<string, number>> = (groupStatus as []).reduce(
      (acc: { [x: string]: { id: number } }, item: { text: string | number; id: number }) => {
        acc[item.text] = { id: item.id };
        return acc;
      },
      {}
    );

    return (
      newStatusObj[text]?.id ??
      newStatusObj[text.toLowerCase()]?.id ??
      newStatusObj[capitalizeFirstLetter(text)]?.id
    );
  }
}
