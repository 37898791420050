import { Notification } from '@/types/notification';

export function mapMessages(messages: Record<string, void | string[]>): string[] {
  const messageList: string[] = [];
  for (const [key, value] of Object.entries(messages)) {
    if (key !== 'message') {
      messageList.push((value as string[])[0]?.replace('.0.', ' ') || (value as string[])[0]);
    }
  }
  return messageList;
}
