import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '@/store';
import { AxiosError } from 'axios';
import { themes } from '@/helpers/theme';
import type { Theme } from '@/helpers/theme';

const types = {
  SET_APP_NAME: 'SET_APP_NAME',
  SET_ERRORS: 'SET_ERRORS',
  CLEAR_ERRORS: 'CLEAR_ERRORS',
  SET_REFERENCE: 'SET_REFERENCE',
  SET_MESSAGES: 'SET_MESSAGES',
  SET_MESSAGES_COLOR: 'SET_MESSAGES_COLOR',
  SET_MESSAGES_BE_CAREFUL: 'SET_MESSAGES_BE_CAREFUL',
};

interface PageState {
  appName: string;
  errors: Record<string, Array<string> | void>;
  reference: string;
  messages: string[];
  messagesColor: string;
  messagesBeCareful: string;
}

//Page helpers
const isDefaultManifestConfig = (state: PageState): boolean => {
  const defaultSections = ['login', 'dashboard'];
  return defaultSections.includes(state.appName);
};

const state = (): PageState => ({
  appName: 'dashboard',
  errors: {},
  reference: '',
  messages: [],
  messagesColor: 'warning',
  messagesBeCareful: '',
});

const actions: ActionTree<PageState, RootState> = {
  async setAppName({ commit, dispatch }, path: string): Promise<void> {
    const name: string = path.split('/')[1];
    await commit(types.SET_APP_NAME, name);
    dispatch('setFavIcon');
    dispatch('setManifestFile');
    dispatch('setTheme');
  },
  setFavIcon({ state }): void {
    const favicons: NodeListOf<Element> = document.querySelectorAll(
      "[rel~='icon'], [rel~='apple-touch-icon'], [rel~='mask-icon']"
    );
    const basePath: string = !isDefaultManifestConfig(state) ? `/manifests/${state.appName}` : '/img/icons';
    favicons.forEach((favicon: Element) => {
      const href: string = (favicon as HTMLLinkElement).href;
      const icon: string = href.split('/').pop() as string;
      (favicon as HTMLLinkElement).href = `${basePath}/${icon}`;
    });
  },
  setManifestFile({ state }): void {
    const file = 'manifest.json';
    const path: string = !isDefaultManifestConfig(state) ? `/manifests/${state.appName}` : '';
    (document.querySelector("link[rel='manifest']") as HTMLLinkElement).href = `${path}/${file}`;
  },
  setTheme({ state }): void {
    const theme: Theme = !isDefaultManifestConfig(state) ? (state.appName as Theme) : ('default' as Theme);
    (document.querySelector("meta[name~='theme-color']") as HTMLMetaElement).content = themes[theme];
    (document.querySelector("link[rel~='mask-icon']") as any).color = themes[theme];
    (document.querySelector("meta[name~='msapplication-TileColor']") as HTMLMetaElement).content =
      themes[theme];
  },
  setPageErrors({ commit }, errors: unknown): void {
    commit(types.SET_ERRORS, errors);
  },
  clearErrors({ commit }): void {
    commit(types.CLEAR_ERRORS);
  },
  setReference({ commit }, reference: string): void {
    commit(types.SET_REFERENCE, reference);
  },
  addMessage({ state, commit }, message: string): void {
    const messages = [...state.messages, message];
    commit(types.SET_MESSAGES, messages);
  },
  addMessageBeCareful({ commit }, message: string): void {
    commit(types.SET_MESSAGES_BE_CAREFUL, message);
  },
  addMessageColor({ commit }, messageColor: string): void {
    commit(types.SET_MESSAGES_COLOR, messageColor);
  },
  removeMessage({ state, commit }, message: string): void {
    const messages = [...state.messages].filter((stateMessage: string) => stateMessage !== message);
    commit(types.SET_MESSAGES, messages);
  },
  clearMessages({ commit }): void {
    commit(types.SET_MESSAGES, []);
    commit(types.SET_MESSAGES_BE_CAREFUL, '');
    commit(types.SET_MESSAGES_COLOR, 'warning');
  },
};

const mutations: MutationTree<PageState> = {
  [types.SET_APP_NAME](state: PageState, name: string): void {
    state.appName = name;
  },
  [types.SET_ERRORS](state: PageState, errors: AxiosError): void {
    state.errors = errors?.response?.data?.errors;
  },
  [types.CLEAR_ERRORS](state: PageState): void {
    state.errors = {};
  },
  [types.SET_REFERENCE](state: PageState, reference: string): void {
    state.reference = reference;
  },
  [types.SET_MESSAGES](state: PageState, messages: string[]): void {
    state.messages = messages;
  },
  [types.SET_MESSAGES_COLOR](state: PageState, messagesColor: string): void {
    state.messagesColor = messagesColor;
  },
  [types.SET_MESSAGES_BE_CAREFUL](state: PageState, messages: string): void {
    state.messagesBeCareful = messages;
  },
};

const getters: GetterTree<PageState, RootState> = {
  getHasErrors: (state: PageState): boolean => {
    return Object.keys(state.errors).length > 0;
  },
  getMessage: (state: PageState): string => {
    return state.messages.sort().join(' + ');
  },
  getMessageCareFul: (state: PageState): string => {
    return state.messagesBeCareful;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
