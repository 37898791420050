import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useStore } from 'vuex';

// components
import { TheNavBar } from '@/components/features';
import { BasePageWrapper } from '@/components/partials';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();

const initialiseStore: Promise<void> = store.dispatch('auth/initialiseStore');

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(TheNavBar), { id: "nav" }),
    _createVNode(_unref(BasePageWrapper), null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ], 64))
}
}

})