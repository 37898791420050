import ComponentAlert from '@/components/partials/BaseAlert/BaseAlert.vue';
import ComponentButton from '@/components/partials/BaseButton/BaseButton.vue';
import ComponentImage from '@/components/partials/BaseImage/BaseImage.vue';
import ComponentNotification from '@/components/partials/Notification/Notification.vue';
import ComponentSpacer from '@/components/partials/BaseSpacer/BaseSpacer.vue';
import ComponentText from '@/components/partials/BaseText/BaseText.vue';
import ComponentLoadingSpinner from '@/components/partials/LoadingSpinner/LoadingSpinner.vue';
import ComponentBasePageWrapper from '@/components/partials/BasePageWrapper/BasePageWrapper.vue';

export const BaseAlert = ComponentAlert;
export const BaseButton = ComponentButton;
export const BaseImage = ComponentImage;
export const BaseSpacer = ComponentSpacer;
export const BaseText = ComponentText;
export const LoadingSpinner = ComponentLoadingSpinner;
export const Notification = ComponentNotification;
export const BasePageWrapper = ComponentBasePageWrapper;
