import ComponentTheNavBar from '@/components/features/TheNavBar/TheNavBar.vue';
import ComponentProductCard from '@/components/features/ProductCard/ProductCard.vue';
import ComponentProductList from '@/components/features/ProductList/ProductList.vue';
import ComponentProductListItem from '@/components/features/ProductList/ProductListItem.vue';
import ComponentDropDownList from '@/components/features/DropDownList/DropDownList.vue';
import ComponentDropDownListItem from '@/components/features/DropDownListItem/DropDownListItem.vue';
import ComponentSearchSuggestions from '@/components/features/SearchSuggestions/SearchSuggestions.vue';
import ComponentReturnTemplate from '@/components/features/ReturnTemplate/ReturnTemplate.vue';
import ComponentToyTemplate from '@/components/features/ToyTemplate/ToyTemplate.vue';

export const TheNavBar = ComponentTheNavBar;
export const ProductCard = ComponentProductCard;
export const ProductList = ComponentProductList;
export const ProductListItem = ComponentProductListItem;
export const DropDownList = ComponentDropDownList;
export const DropDownListItem = ComponentDropDownListItem;
export const SearchSuggestions = ComponentSearchSuggestions;
export const ReturnTemplate = ComponentReturnTemplate;
export const ToyTemplate = ComponentToyTemplate;
