import { RouteRecordRaw } from 'vue-router';

const inboundRoutes: Array<RouteRecordRaw> = [
  {
    path: '/inbound',
    name: 'Inbound',
    component: () => import(/* webpackChunkName: "inbound" */ '../views/inbound/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Inbound returns',
    },
  },
  {
    path: '/inbound/:user_id/active-return',
    name: 'Returns',
    component: () =>
      import(/* webpackChunkName: "inbound-active-return" */ '../views/inbound/active-return/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Process return',
    },
  },
  {
    path: '/inbound/:user_id/active-return/toy/:toy_id',
    name: 'Return',
    component: () =>
      import(
        /* webpackChunkName: "inbound-active-return-toy" */ '../views/inbound/active-return/toy/_toy.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Process return',
    },
  },
  {
    path: '/inbound/:user_id/toybox',
    name: 'Toybox',
    component: () => import(/* webpackChunkName: "inbound-toybox" */ '../views/inbound/toybox/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Toybox',
    },
  },
  {
    path: '/inbound/:user_id/toybox/toy/:toy_id',
    name: 'Toybox Toy',
    component: () =>
      import(/* webpackChunkName: "inbound-toybox-toy" */ '../views/inbound/toybox/toy/_toy.vue'),
    meta: {
      requiresAuth: true,
      title: 'Process return',
    },
  },
  {
    path: '/inbound/:user_id/toybox/unexpected',
    name: 'Unexpected',
    component: () =>
      import(
        /* webpackChunkName: "inbound-toybox-unexpected" */ '../views/inbound/toybox/unexpected/index.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Not a Whirli Toy',
    },
  },
  {
    path: '/inbound/:user_id/confirm',
    name: 'Confirm Return',
    component: () => import(/* webpackChunkName: "inbound-confirm" */ '../views/inbound/confirm/index.vue'),
    meta: {
      requiresAuth: true,
      title: 'Process return',
    },
  },
];

export default inboundRoutes;
