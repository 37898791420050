import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["action", "method"]



// keys
import { formMethods } from '@/types/form';

//types
import type { PropType } from 'vue';
import type { FormMethod } from '@/types/form';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseForm',
  props: {
  action: {
    type: String as PropType<string>,
    default: null,
  },
  method: {
    type: String as PropType<FormMethod>,
    default: 'post',
    validator: (value: FormMethod) => {
      return formMethods.includes(value);
    },
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    action: __props.action,
    method: __props.method,
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('form-submitted')), ["prevent"])),
    class: "form"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 40, _hoisted_1))
}
}

})