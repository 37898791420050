import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { createMiddleware } from '@/helpers/router';
import { RouteMiddlewareType } from '@/types/router';

// Routes
import baseRoutes from './base';
import stockRoutes from './stock';
import authRoutes from './auth';
import inboundRoutes from './inbound';
import pickingRoutes from './picking';
import packingRoutes from './packing';

// Middleware
import tokenMiddleware from '@/router/middleware/tokenMiddleware';
import developmentOnlyMiddleware from '@/router/middleware/developmentOnlyMiddleware';
// import requiresProductMiddleware from '@/router/middleware/requiresProductMiddleware';

const routes: Array<RouteRecordRaw> = [
  ...baseRoutes,
  ...authRoutes,
  ...stockRoutes,
  ...inboundRoutes,
  ...pickingRoutes,
  ...packingRoutes,
];

let router = createRouter({
  history: createWebHistory(),
  routes,
});

// Middleware
const middleware: RouteMiddlewareType[] = [tokenMiddleware, developmentOnlyMiddleware];

router = createMiddleware(router, middleware);

export default router;
