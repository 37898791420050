import { Router } from 'vue-router';
import { isJestRunning } from '@/helpers/tests/jestHelper';
import useEnvironment from '@/@use/environment';
const { isDevelopmentMode } = useEnvironment();

export default function developmentOnlyMiddleware(router: Router): Router {
  if (isJestRunning()) return router;

  router.beforeEach((to) => {
    if (to.meta.developmentOnly && !isDevelopmentMode.value) {
      return {
        path: '/dashboard',
      };
    }
  });

  return router;
}
