import { ColorMap } from '@/types/color';
import { SizeMap } from '@/types/size';

export const colorMap: ColorMap = {
  default: '',
  'default-alt': 'button--default-alt',
  'default-dark': 'button--default-dark',
  success: 'button--success',
  warning: 'button--warning',
  'warning-alt': 'button--warning-alt',
  danger: 'button--danger',
  white: 'button--white',
  'white-secondary': 'button--white-secondary',
};

export const sizeMap: SizeMap = {
  small: '',
  large: 'button--large',
};
