import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-theme"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

// types
import type { ComputedRef } from 'vue';
import type { ThemeColor } from '@/types/theme';

// keys
import { themeColors } from '@/types/theme';

// components
import { BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseText } from '@/components/partials';
import { Modal } from '@/components/containers';
import { BaseForm } from '@/components/partials/forms';


export default /*@__PURE__*/_defineComponent({
  __name: 'TheNavBar',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();

const isAuth: ComputedRef<string> = computed(() => store.state.auth.user.id);
const pageTitle: ComputedRef<string> = computed(() => route.meta.title as string);
const pageReference: ComputedRef<string> = computed(() => store.state.page.reference);
const isDashboard: ComputedRef<boolean> = computed(() => route.path === '/dashboard');
const navBarTheme: ComputedRef<string> = computed(() => {
  const appName: string = route.fullPath.split('/')[1];
  let theme;
  switch (appName) {
    case 'inbound':
      theme = 'quaternary';
      break;
    case 'stock':
      theme = 'secondary';
      break;
    case 'picking':
      theme = 'primary';
      break;
    case 'packing':
      theme = 'tertiary';
      break;
    default:
      theme = 'white';
  }
  return theme;
});

const concatinatedTitle: ComputedRef<string> = computed(
  () => `${pageTitle.value}${pageReference.value.length > 0 ? ':' : ''} ${pageReference.value}`
);

const onLogout = async (): Promise<void> => {
  await store.dispatch('auth/logout');
};

const goToDashboard = async (): Promise<void> => {
  store.dispatch('page/clearMessages');
  router.push({ name: 'Dashboard' });
};

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "nav",
    "data-theme": navBarTheme.value
  }, [
    (pageTitle.value)
      ? (_openBlock(), _createBlock(_unref(BaseText), {
          key: 0,
          tag: "h2",
          size: { default: 'base' },
          class: "nav__title"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(concatinatedTitle.value), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (isAuth.value)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (isDashboard.value)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/dashboard"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    ref: "dashboard",
                    onClick: onLogout,
                    icon: "sign-out-alt"
                  }, null, 512)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_unref(Modal), {
                key: 1,
                color: "default"
              }, {
                toggleButton: _withCtx(({ clicked }) => [
                  _createVNode(_component_font_awesome_icon, {
                    ref: "view-app",
                    icon: "th-large",
                    onClick: ($event: any) => (clicked('open'))
                  }, null, 8, ["onClick"])
                ]),
                default: _withCtx(({ clicked }) => [
                  _createVNode(_unref(BaseForm), {
                    ref: "modal",
                    onFormSubmitted: goToDashboard,
                    class: "nav-modal"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BaseGrid), null, {
                        default: _withCtx(() => [
                          _createVNode(_unref(BaseColumn), null, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createElementVNode("div", { class: "nav-modal__content" }, "Are you sure you want to return to the Dashboard?", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_unref(BaseColumn), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BaseButton), {
                                type: "submit",
                                color: "success"
                              }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode("Confirm")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(BaseColumn), null, {
                            default: _withCtx(() => [
                              _createVNode(_unref(BaseButton), {
                                type: "submit",
                                color: "default-alt",
                                onClick: _withModifiers(($event: any) => (clicked('close')), ["prevent"])
                              }, {
                                default: _withCtx(() => _cache[2] || (_cache[2] = [
                                  _createTextVNode(" Cancel ")
                                ])),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1536)
                ]),
                _: 1
              }))
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}
}

})