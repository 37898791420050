import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { ColorMap } from '@/types/color';
import { SizeMap } from '@/types/size';

interface Prop {
  color?: string;
  size?: string;
}

interface Map {
  color?: ColorMap;
  size?: SizeMap;
}

interface ClassMap {
  colorClass: ComputedRef<string>;
  sizeClass: ComputedRef<string>;
}

export default function (props: Prop, maps: Map): ClassMap {
  const colorClass: ComputedRef<string> = computed((): string => maps.color?.[props.color as string] || '');
  const sizeClass: ComputedRef<string> = computed((): string => maps.size?.[props.size as string] || '');
  return {
    colorClass,
    sizeClass,
  };
}
