import { Router } from 'vue-router';
import { isJestRunning } from '@/helpers/tests/jestHelper';

export default function tokenMiddleware(router: Router): Router {
  if (isJestRunning()) return router;

  router.beforeEach((to) => {
    if (to.meta.requiresAuth && !localStorage.getItem('token')) {
      return {
        path: '/',
        query: { redirect: to.fullPath }, // save the location we were at to come back later
      };
    } else if (to.meta.requiresGuest && localStorage.getItem('token')) {
      return {
        path: '/dashboard',
      };
    }
  });

  return router;
}
