import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { reactive, computed } from 'vue';
import { useStore } from 'vuex';

// components
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseForm, BaseInput } from '@/components/partials/forms';
import { BaseText, BaseButton, BaseSpacer } from '@/components/partials';

// types
import type { LoginFormDetails, AuthParams } from '@/types/auth';
import type { ComputedRef } from 'vue';

// helpers
import { HelperFormErrors } from '@/helpers/form';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const store = useStore();

const loginFormDetails: LoginFormDetails = reactive({
  email: '',
  password: '',
  errors: new HelperFormErrors(),
});

const onLoginFormSubmitted = async () => {
  const loginCredentials: AuthParams = {
    email: loginFormDetails.email,
    password: loginFormDetails.password,
  };
  const login = await store.dispatch('auth/login', loginCredentials);
  if (!login.success) loginFormDetails.errors.record(login.errors);
};

const loading: ComputedRef<boolean> = computed(() => store.getters['auth/getStoreLoading']);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseText), {
        tag: "h1",
        size: { default: '3xl' }
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Login")
        ])),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer), { size: "4" }),
      _createVNode(_unref(BaseForm), { onFormSubmitted: onLoginFormSubmitted }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseGrid), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: "Email address",
                    name: "email",
                    type: "email",
                    value: loginFormDetails.email,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((loginFormDetails.email) = $event)),
                    "onReset:errors": _cache[1] || (_cache[1] = ($event: any) => (loginFormDetails.errors.clear('email'))),
                    error: loginFormDetails.errors.get('email')
                  }, null, 8, ["value", "error"])
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseInput), {
                    label: "Password",
                    name: "password",
                    type: "password",
                    value: loginFormDetails.password,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((loginFormDetails.password) = $event)),
                    "onReset:errors": _cache[3] || (_cache[3] = ($event: any) => (loginFormDetails.errors.clear('password'))),
                    error: loginFormDetails.errors.get('password')
                  }, null, 8, ["value", "error"]),
                  _createVNode(_unref(BaseSpacer), { size: "2" })
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseButton), {
                    type: "submit",
                    color: "success",
                    "is-loading": loading.value
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Login")
                    ])),
                    _: 1
                  }, 8, ["is-loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})