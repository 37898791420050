import Login from '@/views/auth/Login.vue';
import { RouteRecordRaw } from 'vue-router';

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresGuest: true,
      title: 'Whirli Operations: Login',
    },
  },
];

export default authRoutes;
