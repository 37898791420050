import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt", "height", "width"]

import { computed, onMounted, ref } from 'vue';

//types
import type { PropType, ComputedRef, Ref } from 'vue';
import type { Image } from '@/types/image';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseImage',
  props: {
  image: {
    required: true,
    type: Object as PropType<Image>,
  },
},
  setup(__props) {

const defaultImage = ref(null) as Ref<HTMLElement | null>;

const props = __props;

const height: ComputedRef<string> = computed(() => {
  const percentage = (parseInt(props.image.height) / parseInt(props.image.width)) * 100;
  return `${percentage}%`;
});

const loadImage: () => void = (): void => {
  (defaultImage.value as unknown as HTMLElement).addEventListener('load', () => {
    (defaultImage.value as unknown as HTMLElement).setAttribute('loaded', 'true');
  });
};

onMounted((): void => {
  loadImage();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "image__wrapper",
    style: _normalizeStyle(`padding-top: ${height.value}`)
  }, [
    _createElementVNode("picture", null, [
      _createElementVNode("img", {
        class: "image",
        src: __props.image.url,
        alt: __props.image.title,
        height: __props.image.height,
        width: __props.image.width,
        ref_key: "defaultImage",
        ref: defaultImage,
        loading: "lazy"
      }, null, 8, _hoisted_1)
    ])
  ], 4))
}
}

})