import { HelperFormErrors } from '@/helpers/form';
import { SearchTypes } from '@/constants/searchTypes';

export const formMethods = ['get', 'post'];
export const inputTypes = ['text', 'email', 'password', 'search', 'textarea', 'number', 'file', 'checkbox'];

export type FormMethod = typeof formMethods[number];
export type InputType = typeof inputTypes[number];

export interface FormErrors {
  [key: string]: string[];
}

export interface SearchForm {
  searchPrefix: string;
  searchTerm: string;
  searchType: SearchTypes;
  errors: HelperFormErrors;
}

export interface SelectBinForm {
  name: string;
  errors: HelperFormErrors;
}

export interface StockCheckForm {
  stock: number | null;
  errors: HelperFormErrors;
}

export interface UnexpectedItemForm {
  comment: string;
  images: File[] | null;
  errors: HelperFormErrors;
}

export interface SuggestSearchForm {
  search: string;
}
