import ComponentMain from '@/components/primitives/BaseMain/BaseMain.vue';
import ComponentAside from '@/components/primitives/BaseAside/BaseAside.vue';
import ComponentSection from '@/components/primitives/BaseSection/BaseSection.vue';
import ComponentContainer from '@/components/primitives/BaseContainer/BaseContainer.vue';
import ComponentGrid from '@/components/primitives/BaseGrid/BaseGrid.vue';
import ComponentColumn from '@/components/primitives/BaseColumn/BaseColumn.vue';

export const BaseMain = ComponentMain;
export const BaseAside = ComponentAside;
export const BaseSection = ComponentSection;
export const BaseContainer = ComponentContainer;
export const BaseGrid = ComponentGrid;
export const BaseColumn = ComponentColumn;
