import type BaseUserInterface from 'whirli-client/dist/Interfaces/user/User';
import type { WopsOrderInterface } from './order';
import type { WopsReturnOrderInterface } from './returnOrder';

export interface WopsUserInterface extends Omit<BaseUserInterface, 'activeReturnOrder' | 'orders'> {
  id?: string;
  fullName?: string;
  activeReturnOrder?: WopsReturnOrderInterface[];
  orders?: WopsOrderInterface[];
}

export default class WopsUser implements WopsUserInterface {
  id?: string;
  firstname?: string;
  lastname?: string;
  activeReturnOrder?: WopsReturnOrderInterface[];
  orders?: WopsOrderInterface[];

  constructor(page: WopsUserInterface) {
    Object.assign(this, page);
  }

  get fullName(): string {
    return `${this.firstname} ${this.lastname}` || '';
  }
}
