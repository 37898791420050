import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["transform"]
const _hoisted_2 = {
  x: "47",
  y: "24",
  rx: "3",
  ry: "6",
  width: "6",
  height: "12"
}
const _hoisted_3 = ["begin"]



// helpers
import { colorMap, sizeMap } from '@/helpers/loading';

// keys
import { actionColors } from '@/types/color';
import { loadingSpinnerSizes } from '@/types/loading';

// types
import type { PropType, ComputedRef } from 'vue';
import type { ActionColor } from '@/types/color';
import type { SpinnerPoint, LoadingSpinnerSize } from '@/types/loading';
import type { Size } from '@/types/spacer';

// @use
import useClassMap from '@/@use/classMap';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string): boolean => actionColors.includes(value),
    default: 'default',
  },
  size: {
    required: false,
    type: String as PropType<LoadingSpinnerSize>,
    validator: (value: LoadingSpinnerSize) => loadingSpinnerSizes.includes(value),
    default: 'base',
  },
},
  setup(__props) {

const props = __props;

const spinnerPoints: SpinnerPoint[] = [
  { rotate: '0 50 50', begin: '-0.9166666666666666s' },
  { rotate: '30 50 50', begin: '-0.8333333333333334s' },
  { rotate: '60 50 50', begin: '-0.75s' },
  { rotate: '90 50 50', begin: '-0.6666666666666666s' },
  { rotate: '120 50 50', begin: '-0.5833333333333334s' },
  { rotate: '150 50 50', begin: '-0.5s' },
  { rotate: '180 50 50', begin: '-0.4166666666666667s' },
  { rotate: '210 50 50', begin: '-0.3333333333333333s' },
  { rotate: '240 50 50', begin: '-0.25s' },
  { rotate: '270 50 50', begin: '-0.16666666666666666s' },
  { rotate: '300 50 50', begin: '-0.08333333333333333s' },
  { rotate: '330 50 50', begin: '0s' },
];

const { colorClass, sizeClass } = useClassMap(props, { color: colorMap, size: sizeMap });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _mergeProps({
    class: [[_unref(colorClass), _unref(sizeClass)], "loading-spinner"],
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    style: {"margin":"auto","background":"none","display":"block","shape-rendering":"auto"},
    viewBox: "25 25 50 50",
    preserveAspectRatio: "xMidYMid"
  }, _ctx.$attrs), [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(spinnerPoints, (point, index) => {
      return _createElementVNode("g", {
        key: index,
        transform: `rotate(${point.rotate})`
      }, [
        _createElementVNode("rect", _hoisted_2, [
          _createElementVNode("animate", {
            attributeName: "opacity",
            values: "1;0",
            keyTimes: "0;1",
            dur: "1s",
            begin: point.begin,
            repeatCount: "indefinite"
          }, null, 8, _hoisted_3)
        ])
      ], 8, _hoisted_1)
    }), 64))
  ], 16))
}
}

})