import { createApp } from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import client from '@/plugins/whirli-client';

import {
  faCheck,
  faExclamationTriangle,
  faTrashAlt,
  faThLarge,
  faSignOutAlt,
  faArrowUp,
  faLink,
  faFilePdf,
} from '@/plugins/font-awesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import type { App as AppCore } from '@vue/runtime-core';

library.add(
  faCheck,
  faExclamationTriangle,
  faTrashAlt,
  faThLarge,
  faSignOutAlt,
  faArrowUp,
  faLink,
  faFilePdf
);

const app: AppCore = createApp(App);

app.use(router);
app.use(store);
app.use(client);
app.component('font-awesome-icon', FontAwesomeIcon);

function getSentryEnvironmentName() {
  if (process.env.SENTRY_ENV_NAME && process.env.SENTRY_ENV_NAME.length) return process.env.SENTRY_ENV_NAME;

  return process.env.NODE_ENV === 'development' ? 'development' : 'production';
}

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  logErrors: true,
  trackComponents: true,
  hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', process.env.VUE_APP_SENTRY_TRACING_ORIGIN as string, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE as unknown as number,
  environment: getSentryEnvironmentName(),
});

app.mount('#app');
