import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-state"]
const _hoisted_2 = {
  key: 0,
  class: "modal__header"
}
const _hoisted_3 = { class: "modal__title" }
const _hoisted_4 = {
  key: 0,
  class: "modal__controls"
}
const _hoisted_5 = { class: "modal__details" }

import { ref, computed } from 'vue';

// components
import { BaseButton, BaseText } from '@/components/partials';

// helpers
import { colorMap } from '@/helpers/modal';

// keys
import { modalStates } from '@/types/modal';

// types
import type { ActionColor } from '@/types/color';
import type { PropType, Ref, ComputedRef } from 'vue';
import type { ModalState } from '@/types/modal';

// @use
import useClassMap from '@/@use/classMap';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  props: {
  title: {
    required: false,
    type: String as PropType<string>,
    default: '',
  },
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string) => Object.keys(colorMap).includes(value),
    default: 'default',
  },
  openButtonText: {
    required: false,
    type: String as PropType<string>,
  },
  showOpenButton: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: true,
  },
  parentState: {
    required: false,
    type: String as PropType<ModalState>,
    validator: (state: ModalState) => modalStates.includes(state),
  },
  fullscreen: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  emits: ['modal:close', 'modal:open'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const localState: Ref<ModalState> = ref('closed');

const state: ComputedRef<ModalState> = computed(() => {
  return props.parentState === undefined ? localState.value : props.parentState;
});

const toggleLocalState: (newState: ModalState) => void = (newState: ModalState): void => {
  if (newState === 'open') emits('modal:open');
  localState.value = newState;
};

const { colorClass } = useClassMap(props, { color: colorMap });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "toggleButton", { clicked: toggleLocalState }, () => [
      (__props.showOpenButton && !__props.parentState)
        ? (_openBlock(), _createBlock(_unref(BaseButton), {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleLocalState('open')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.openButtonText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      id: "modal",
      class: "overlay",
      "data-state": state.value
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal", [_unref(colorClass), { fullscreen: __props.fullscreen }]])
      }, [
        (__props.title.trim().length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(BaseText), {
                  tag: "h2",
                  size: { default: 'md', tablet: 'md' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(__props.title), 1)
                  ]),
                  _: 1
                })
              ]),
              (!__props.parentState)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("button", {
                      class: "modal__close",
                      "aria-label": "Close modal",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.$emit('modal:close');
              toggleLocalState('closed');
            })
                    }, _cache[2] || (_cache[2] = [
                      _createTextVNode(" × "),
                      _createElementVNode("span", { class: "sr-only" }, "Close Modal", -1)
                    ]))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", { clicked: toggleLocalState })
        ])
      ], 2)
    ], 8, _hoisted_1)
  ], 64))
}
}

})