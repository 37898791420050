import BaseBinInterface from 'whirli-client/dist/Interfaces/bin/Bin';
import { WopsStockInterface } from '@/classes/stock';

export interface WopsBinInterface extends Partial<Omit<BaseBinInterface, 'stock'>> {
  stock?: WopsStockInterface[];
}

export default class WopsBin implements Partial<WopsBinInterface> {
  stock?: WopsStockInterface[];

  constructor(page: WopsBinInterface) {
    Object.assign(this, page);
  }
}
