import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed } from 'vue';

// components
import ButtonLink from '@/components/partials/BaseButton/ButtonLink.vue';
import ButtonDefault from '@/components/partials/BaseButton/ButtonDefault.vue';

//helpers
import { colorMap, sizeMap } from '@/helpers/button';

// keys
import { actionColors } from '@/types/color';
import { buttonSizes, buttonTypes } from '@/types/button';

// types
import type { PropType, ButtonHTMLAttributes, Component, ComputedRef } from 'vue';
import type { ActionColor } from '@/types/color';
import type { ButtonSize, ButtonType, ButtonLinkData } from '@/types/button';

// @use
import useClassMap from '@/@use/classMap';

// components
import { LoadingSpinner } from '@/components/partials';

// define props

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
  color: {
    required: false,
    type: String as PropType<ActionColor>,
    validator: (value: string): boolean => actionColors.includes(value),
    default: 'default',
  },
  size: {
    required: false,
    type: String as PropType<ButtonSize>,
    validator: (value: ButtonSize) => buttonSizes.includes(value),
    default: 'small',
  },
  type: {
    required: false,
    type: String as PropType<ButtonType>,
    validator: (value: ButtonType) => buttonTypes.includes(value),
  },
  url: {
    required: false,
    type: String as PropType<string>,
  },
  buttonDisabled: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isLoading: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isComplete: {
    required: false,
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { colorClass, sizeClass } = useClassMap(props, { color: colorMap, size: sizeMap });

const disabled = computed(() => {
  return { 'button--disabled': props.buttonDisabled };
});

const button: ComputedRef<Component> = computed((): Component => {
  return props.url !== undefined ? ButtonLink : ButtonDefault;
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(button.value), _mergeProps({
    disabled: __props.buttonDisabled || __props.isLoading,
    type: __props.type,
    url: __props.url,
    class: ["button", __props.buttonDisabled ? [disabled.value, _unref(sizeClass)] : [_unref(colorClass), _unref(sizeClass)]]
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (__props.isLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_unref(LoadingSpinner), {
              color: __props.color,
              size: "small",
              class: "button__loading-icon"
            }, null, 8, ["color"]),
            _renderSlot(_ctx.$slots, "loading", {}, () => [
              _cache[0] || (_cache[0] = _createTextVNode("Processing…"))
            ])
          ], 64))
        : (__props.isComplete)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_font_awesome_icon, {
                icon: "check",
                class: "button__done-icon"
              }),
              _renderSlot(_ctx.$slots, "complete", {}, () => [
                _cache[1] || (_cache[1] = _createTextVNode("Processing…"))
              ])
            ], 64))
          : _renderSlot(_ctx.$slots, "default", { key: 2 })
    ]),
    _: 3
  }, 16, ["disabled", "type", "url", "class"]))
}
}

})